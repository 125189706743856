import React from 'react';

const Modal = ({isOpen, onClose, children}) => {
  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center'>
      <div className='bg-[#dce5e7] p-5 rounded-lg shadow-lg'>
        <div className='w-full flex justify-end'>
          <button
            onClick={onClose}
            className='relative flex self-end top-0 right-0 m-2'>
            X
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
