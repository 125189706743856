import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import IcoArrow from '../../assets/ico-arrow.png';
import MensajeValidacion from '../validacion/MensajeValidacion';
import MensajeError from '../common/MensajeError';
import {CuentaAPI} from '../../services/cuenta';
import {FaUserCheck, FaEye, FaEyeSlash} from 'react-icons/fa';
import * as Sentry from '@sentry/react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {tipoEmpresa} from '../../models/tipoEmpresa';
import './FormularioRegistro.css';

const FormularioRegistro = () => {
  const navigate = useNavigate();

  //max length contenidos
  const direccionExactaMaxLength = 500;
  const nombreMaxLength = 128;
  const apellidosMaxLength = 128;
  const tipoNegocioMaxLength = 128;
  const nombreEmpresaMaxLength = 256;
  const correoElectronicoMaxLength = 256;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmarEmail, setConfirmarEmail] = useState('');
  const [confirmarEmailError, setConfirmarEmailError] = useState('');
  const [tipoIdentificacion, setTipoIdentificacion] = useState(
    tipoEmpresa.cedulaFisica,
  );
  const [identificacion, setIdentificacion] = useState('');
  const [identificacionError, setIdentificacionError] = useState('');
  const [nombre, setNombre] = useState('');
  const [nombreError, setNombreError] = useState('');
  const [Apellidos, setApellidos] = useState('');
  const [apellidosError, setApellidosError] = useState('');
  const [telefono, setTelefono] = useState('');
  const [telefonoError, setTelefonoError] = useState('');
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [nombreEmpresaError, setNombreEmpresaError] = useState('valid');
  const [clave, setClave] = useState('');
  const [claveError, setClaveError] = useState('');
  const [confirmarClave, setConfirmarClave] = useState('');
  const [confirmarClaveError, setConfirmarClaveError] = useState('');
  const [errorGeneral, setErrorGeneral] = useState('');
  const [pasoActual, setPasoActual] = useState('1');
  const [tipoNegocio, setTipoNegocio] = useState('');
  const [tipoNegocioError, setTipoNegocioError] = useState('');
  const [jsonData, setJsonData] = useState(null);
  const [provincias, setProvincias] = useState([]);
  const [provincia, setProvincia] = useState('');
  const [provinciaError, setProvinciaError] = useState('');
  const [cantones, setCantones] = useState([]);
  const [canton, setCanton] = useState('');
  const [cantonError, setCantonError] = useState('');
  const [distritos, setDistritos] = useState([]);
  const [distrito, setDistrito] = useState('');
  const [distritoError, setDistritoError] = useState('');
  const [direccionExacta, setDireccionExacta] = useState('');
  const [direccionExactaError, setDireccionExactaError] = useState('');
  const [animation, setAnimation] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [errorResponse, setErrorResponse] = useState([]);
  const [procesando, setProcesando] = useState(false);
  const [finalizado, setFinalizado] = useState(false);
  const [mostrarClave, setMostrarClave] = useState(false);
  const [mostrarConfirmacionClave, setMostrarConfirmacionClave] =
    useState(false);

  const hasIniciarSesion = errorResponse.some(error =>
    error.toLowerCase().includes('inicie sesión'),
  );

  const notifySuccess = message => toast.success(message);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/geodata.json');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setJsonData(data);

        if (data) {
          const objProvincias = Object.keys(data.provincias).map(key => ({
            id: key,
            nombre: data.provincias[key].nombre,
          }));

          setProvincias(objProvincias);
        }
      } catch (error) {
        console.error('Error fetching the JSON file:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (provincia !== '') {
      setProvinciaError('valid');
      const cantonesPorProvincia = jsonData.provincias[provincia];
      if (cantonesPorProvincia) {
        const objCantones = Object.keys(cantonesPorProvincia.cantones).map(
          key => ({
            id: key,
            nombre: cantonesPorProvincia.cantones[key].nombre,
          }),
        );
        setCantones(objCantones);
      }
    }
  }, [provincia]);

  useEffect(() => {
    if (canton !== '') {
      setCantonError('valid');
      const distritosPorProvincia =
        jsonData.provincias[provincia]?.cantones[canton];
      if (distritosPorProvincia) {
        const objDistrito = Object.keys(distritosPorProvincia.distritos).map(
          key => ({
            id: key,
            nombre: distritosPorProvincia.distritos[key],
          }),
        );
        setDistritos(objDistrito);
      }
    }
  }, [canton]);

  useEffect(() => {
    if (distrito !== '') {
      setDistritoError('valid');
    }
  }, [distrito]);

  useEffect(() => {
    if (pasoActual === '3') {
      const interval = setInterval(() => {
        setAnimation(true);
        setTimeout(() => {
          setAnimation(false);
        }, 1000); // Adjust the duration of animation as needed
      }, 1000); // Adjust the interval between animations as needed

      return () => clearInterval(interval);
    }
  }, [pasoActual]);

  const validateConfirmarEmail = emailValue => {
    if (emailValue !== email) {
      setConfirmarEmailError(
        'Las direcciones de correo electrónico no coinciden.',
      );
    } else {
      setConfirmarEmailError('valid');
    }
  };

  const validateEmail = email => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const esValido = emailPattern.test(email);
    if (!esValido) {
      setEmailError('Debe ingresar un correo electrónico válido.');
    } else {
      setEmailError('valid');
    }
  };

  const handleEmailChange = e => {
    setEmailError('');
    const emailValue = e.target.value;
    setEmail(emailValue);
    validateEmail(emailValue);
  };

  const handleConfirmarEmailChange = e => {
    setConfirmarEmailError('');
    const emailValue = e.target.value;
    setConfirmarEmail(emailValue);
    validateConfirmarEmail(emailValue);
  };

  const handleTipoIdentificacionChange = e => {
    const tipoIdentificacionValue = Number(e.target.value);
    if (e.target.value === tipoEmpresa.cedulaJuridica) {
      setNombreEmpresaError('');
    }

    setTipoIdentificacion(tipoIdentificacionValue);
  };

  const handleDireccionExactaChange = event => {
    if (event.target.value.length <= direccionExactaMaxLength) {
      setDireccionExacta(event.target.value);
    }
  };

  const verificarNombre = () => {
    if (!nombre || nombre === '') {
      setNombreError('El nombre es requerido.');
    } else {
      setNombreError('valid');
    }
  };

  const verificarApellidos = () => {
    if (!Apellidos || Apellidos === '') {
      setApellidosError('Al menos 1 apellido es requerido.');
    } else {
      setApellidosError('valid');
    }
  };

  const verificarTelefono = () => {
    if (!telefono || telefono === '') {
      setTelefonoError('El teléfono es requerido.');
    } else {
      const telefonoSinMascara = telefono.replace('_', '');
      if (telefonoSinMascara.length !== 9) {
        setTelefonoError('Debe ingresar un teléfono válido');
        return;
      }

      setTelefonoError('valid');
    }
  };

  const verificarNombreEmpresa = () => {
    if (
      tipoIdentificacion === tipoEmpresa.cedulaJuridica &&
      (!nombreEmpresa || nombreEmpresa === '')
    ) {
      setNombreEmpresaError('El nombre de empresa es requerido.');
    } else {
      setNombreEmpresaError('valid');
    }
  };

  const verificaParametrosClave = claveValue => {
    if (claveValue !== '') {
      // Check for password length
      setIsLengthValid(claveValue.length >= 8);
      // Check for uppercase letter using regex
      setHasUppercase(/[A-Z]/.test(claveValue));
    }
  };

  const cambiarVisibilidadClave = () => {
    setMostrarClave(!mostrarClave);
  };

  const cambiarVisibilidadConfirmacionClave = () => {
    setMostrarConfirmacionClave(!mostrarConfirmacionClave);
  };

  const verificarClave = claveValue => {
    if (!claveValue || claveValue === '') {
      setClaveError('La clave es requerida.');
    } else {
      // Check for password length
      setIsLengthValid(claveValue.length >= 8);
      // Check for uppercase letter using regex
      setHasUppercase(/[A-Z]/.test(claveValue));
      if (isLengthValid && hasUppercase) {
        setClaveError('valid');
      } else {
        setClaveError('notvalid');
      }
    }
  };

  const verificarConfirmarClave = confirmarClaveValue => {
    if (!confirmarClaveValue || confirmarClaveValue === '') {
      setConfirmarClaveError('La clave es requerida.');
    } else if (confirmarClaveValue !== clave) {
      setConfirmarClaveError(
        'La confirmación de clave debe coincidir con la clave.',
      );
    } else {
      setConfirmarClaveError('valid');
    }
  };

  const verificarIdentificacion = () => {
    if (!identificacion || identificacion === '') {
      setIdentificacionError('La identificación es requerida.');
    } else {
      const identificacionSinMascara = identificacion.replace('_', '');
      if (tipoIdentificacion === tipoEmpresa.cedulaFisica) {
        if (identificacionSinMascara.length !== 11) {
          setIdentificacionError(
            'Debe ingresar una cédula física válida. (9 dígitos)',
          );
          return;
        }
      }
      if (tipoIdentificacion === tipoEmpresa.cedulaJuridica) {
        if (identificacionSinMascara.length !== 12) {
          setIdentificacionError(
            'Debe ingresar una cédula jurídica válida. (10 dígitos)',
          );
          return;
        }
      }
      setIdentificacionError('valid');
    }
  };

  const verificarTipoNegocio = () => {
    if (!tipoNegocio || tipoNegocio === '') {
      setTipoNegocioError('El tipo de Negocio es requerido.');
    } else {
      setTipoNegocioError('valid');
    }
  };

  const verificarProvincia = () => {
    if (!provincia || provincia === '') {
      setProvinciaError('Debe seleccionar una provincia.');
    } else {
      setProvinciaError('valid');
    }
  };

  const verificarCanton = () => {
    if (!canton || canton === '') {
      setCantonError('Debe seleccionar un canton.');
    } else {
      setCantonError('valid');
    }
  };

  const verificarDistrito = () => {
    if (!distrito || distrito === '') {
      setDistritoError('Debe seleccionar un distrito.');
    } else {
      setDistritoError('valid');
    }
  };

  const verificarDireccionExacta = () => {
    if (!direccionExacta || direccionExacta === '') {
      setDireccionExactaError('La dirección exacta es requerida.');
    } else {
      setDireccionExactaError('valid');
    }
  };

  const siguientePaso = () => {
    setErrorGeneral('');
    //validaciones
    verificarNombre();
    verificarApellidos();
    verificarIdentificacion();
    verificarTelefono();
    validateEmail(email);
    validateConfirmarEmail(confirmarEmail);
    verificarNombreEmpresa();
    verificarClave(clave);
    verificarConfirmarClave(confirmarClave);

    if (
      nombreError === 'valid' &&
      apellidosError === 'valid' &&
      identificacionError === 'valid' &&
      telefonoError === 'valid' &&
      emailError === 'valid' &&
      claveError === 'valid' &&
      nombreEmpresaError === 'valid' &&
      confirmarClaveError === 'valid' &&
      confirmarEmailError === 'valid'
    ) {
      setErrorGeneral('');
      setPasoActual('2');
    } else {
      setErrorGeneral('Debe verificar los campos requeridos para continuar.');
    }
  };

  const enviarFormulario = () => {
    setErrorResponse([]);
    const empresa =
      tipoIdentificacion === tipoEmpresa.cedulaFisica
        ? `${nombre?.trim()} ${Apellidos?.trim()}`
        : nombreEmpresa?.trim();
    const perfilEmpresa = {
      tipo: tipoIdentificacion,
      identificacion: identificacion.trim(),
      nombreEmpresa: empresa?.trim(),
      nombreRepresentante: nombre?.trim(),
      apellidosRepresentante: Apellidos?.trim(),
      telefono: telefono?.trim(),
      tipoNegocio: tipoNegocio?.trim(),
      provincia: provincia?.trim(),
      canton: canton?.trim(),
      distrito: distrito?.trim(),
      direccion: direccionExacta?.trim(),
    };
    const usuario = {
      email: email?.trim().toLowerCase(),
      clave: clave?.trim(),
    };

    const data = {
      infoEmpresa: perfilEmpresa,
      infoCuenta: usuario,
    };

    CuentaAPI.crear(data)
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          setErrorResponse(response.errors);
        } else {
          setProcesando(false);
          setFinalizado(true);
          notifySuccess('Se ha creado tu cuenta de forma correcta.');
        }
      })
      .catch(error => {
        setProcesando(false);
        if (error.response?.data?.error) {
          setErrorResponse([error.response?.data?.error]);
          return;
        }

        const mensajeError = [
          'Ha ocurrido un error al crear la cuenta. Por favor, inténtalo de nuevo.',
        ];
        setErrorResponse(mensajeError);
        Sentry.captureException(error);
      });
  };

  const crearCuenta = () => {
    setErrorGeneral('');

    verificarTipoNegocio();
    verificarProvincia();
    verificarCanton();
    verificarDistrito();
    verificarDireccionExacta();

    if (
      tipoNegocioError === 'valid' &&
      provinciaError === 'valid' &&
      cantonError === 'valid' &&
      distritoError === 'valid' &&
      direccionExactaError === 'valid'
    ) {
      setErrorGeneral('');
      setPasoActual('3');
      setProcesando(true);
      enviarFormulario();
    } else {
      setErrorGeneral('Debe verificar los campos requeridos para continuar.');
    }
  };

  return (
    <>
      <h2 className='text-4xl leading-[88%] text-center my-5 mt-10'>
        Crea tu <span className='font-semibold'>cuenta de empleador</span>
      </h2>
      {pasoActual === '1' && (
        <>
          <div className='mt-12 px-10 w-full flex flex-col justify-center items-center text-[16px] lg:text-[16px]'>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <input
                className={`rounded-[10px] py-3 px-3 w-full bg-gray-100 ${
                  nombreError !== '' && nombreError !== 'valid' && 'bg-red-200'
                }`}
                maxLength={nombreMaxLength}
                placeholder='(*) Nombre'
                value={nombre}
                onChange={e => {
                  if (e.target.value !== '') {
                    setNombreError('valid');
                  } else {
                    setNombreError('El nombre es requerido.');
                  }

                  setNombre(e.target.value);
                }}
              />
              <input
                maxLength={apellidosMaxLength}
                value={Apellidos}
                className={`rounded-[10px] py-3 px-3 w-full bg-gray-100 ${
                  apellidosError !== '' &&
                  apellidosError !== 'valid' &&
                  'bg-red-200'
                }`}
                placeholder='(*) Apellidos'
                onChange={e => {
                  if (e.target.value !== '') {
                    setApellidosError('valid');
                  } else {
                    setApellidosError('Al menos 1 apellido es requerido.');
                  }
                  setApellidos(e.target.value);
                }}
              />
              <MensajeValidacion error={nombreError} />
              <MensajeValidacion error={apellidosError} />
            </div>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <select
                value={tipoIdentificacion}
                onChange={e => handleTipoIdentificacionChange(e)}
                className='py-4 px-2 rounded-[10px] bg-gray-100'>
                <option value={tipoEmpresa.cedulaFisica}>Cédula Física</option>
                <option value={tipoEmpresa.cedulaJuridica}>
                  Cédula Jurídica
                </option>
              </select>
              <InputMask
                className={`rounded-[10px] py-3 px-3 w-full bg-gray-100 ${
                  identificacionError !== '' &&
                  identificacionError !== 'valid' &&
                  'bg-red-200'
                }`}
                value={identificacion.replace('-', '')}
                placeholder={
                  tipoIdentificacion === tipoEmpresa.cedulaFisica
                    ? '0-0000-0000'
                    : '0-000-000000'
                }
                mask={
                  tipoIdentificacion === tipoEmpresa.cedulaFisica
                    ? '9-9999-9999'
                    : '9-999-999999'
                }
                onChange={e => {
                  if (e.target.value !== '') {
                    setIdentificacionError('valid');
                  } else {
                    setIdentificacionError('La identificación es requerida.');
                  }
                  setIdentificacion(e.target.value);
                }}
              />
              <div></div>
              <MensajeValidacion error={identificacionError} />
            </div>
            <div className='w-full py-2'>
              <InputMask
                className={`rounded-[10px] py-3 px-3 w-1/2 sm:w-full mb-3 bg-gray-100 ${
                  telefonoError !== '' &&
                  telefonoError !== 'valid' &&
                  'bg-red-200'
                }`}
                placeholder='(*) Teléfono'
                mask='9999-9999'
                value={telefono.replace('-', '')}
                onChange={e => {
                  if (e.target.value !== '') {
                    setTelefonoError('valid');
                  } else {
                    setTelefonoError('El teléfono es requerido.');
                  }
                  setTelefono(e.target.value);
                }}
              />
              <MensajeValidacion error={telefonoError} />
            </div>
            {tipoIdentificacion === tipoEmpresa.cedulaJuridica && (
              <div className='w-full py-2'>
                <input
                  maxLength={nombreEmpresaMaxLength}
                  className={`rounded-[10px] py-3 px-3 w-full mb-3 bg-gray-100 ${
                    nombreEmpresaError !== '' &&
                    nombreEmpresaError !== 'valid' &&
                    'bg-red-200'
                  }`}
                  placeholder='Nombre de Empresa'
                  onChange={e => {
                    setNombreEmpresa(e.target.value);
                  }}
                />
              </div>
            )}
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <div>
                <input
                  value={email}
                  maxLength={correoElectronicoMaxLength}
                  onChange={e => handleEmailChange(e)}
                  className={`rounded-[10px] py-3 px-3 w-full mb-3 bg-gray-100 ${
                    emailError !== '' && emailError !== 'valid' && 'bg-red-200'
                  }`}
                  placeholder='(*) Correo Electrónico'
                />
                <MensajeValidacion error={emailError} />
              </div>
              <div>
                <input
                  value={confirmarEmail}
                  onChange={e => handleConfirmarEmailChange(e)}
                  className={`rounded-[10px] py-3 px-3 w-full mb-3 bg-gray-100 ${
                    confirmarEmailError !== '' &&
                    confirmarEmailError !== 'valid' &&
                    'bg-red-200'
                  }`}
                  placeholder='(*) Confirmar Correo Electrónico'
                />
                <MensajeValidacion error={confirmarEmailError} />
              </div>
            </div>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <div>
                <div className='relative'>
                  <input
                    type={mostrarClave ? 'text' : 'password'}
                    className={`rounded-[10px] py-3 px-3 w-full mb-3 bg-gray-100 ${
                      claveError !== '' &&
                      claveError !== 'valid' &&
                      'bg-red-200'
                    } ${mostrarClave || clave.length === 0 ? '' : 'password-input'}`}
                    value={clave}
                    placeholder='(*) Contraseña'
                    onChange={e => {
                      verificaParametrosClave(e.target.value);
                      setClave(e.target.value);
                      if (e.target.value !== '') {
                        const isvalidLength = e.target.value.length >= 8;
                        const hasUpperCase = /[A-Z]/.test(e.target.value);

                        if (isvalidLength && hasUpperCase) {
                          setClaveError('valid');
                        } else {
                          setClaveError('notvalid');
                        }
                      } else {
                        setClaveError('notvalid');
                      }
                    }}
                  />
                  <span
                    onClick={cambiarVisibilidadClave}
                    className='absolute inset-y-0 right-0 flex items-center pr-3 pb-2 cursor-pointer'>
                    {mostrarClave ? (
                      <FaEyeSlash className='text-[#FF6652] text-[22px]' />
                    ) : (
                      <FaEye className='text-[#FF6652] text-[22px]' />
                    )}{' '}
                    {/* Eye icons */}
                  </span>
                </div>
                <div className='mt-2 flex flex-col bg-gray-100 p-3 rounded-lg'>
                  <p>
                    {isLengthValid ? (
                      <span className='text-black'>
                        &#10003; Clave contiene al menos 8 caracteres
                      </span>
                    ) : (
                      <span className='text-red-800'>
                        &#10007;{' '}
                        <span className='text-[#FF6652]'>
                          Clave debe contener al menos 8 caracteres
                        </span>
                      </span>
                    )}
                  </p>
                  <p className='mt-2'>
                    {hasUppercase ? (
                      <span className='text-black'>
                        &#10003; Clave contiene al menos una mayúscula
                      </span>
                    ) : (
                      <span className='text-red-800'>
                        &#10007;{' '}
                        <span className='text-[#FF6652]'>
                          Clave debe contener al menos una mayúscula
                        </span>
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div>
                <div className='relative'>
                  <input
                    type={mostrarConfirmacionClave ? 'text' : 'password'}
                    value={confirmarClave}
                    className={`rounded-[10px] py-3 px-3 w-full bg-gray-100 mb-3 ${
                      confirmarClaveError !== '' &&
                      confirmarClaveError !== 'valid' &&
                      'bg-red-200'
                    } ${mostrarConfirmacionClave || confirmarClave.length === 0 ? '' : 'password-input'}`}
                    placeholder='(*) Confirmar Contraseña'
                    onChange={e => {
                      verificarConfirmarClave(e.target.value);
                      setConfirmarClave(e.target.value);
                    }}
                  />
                  <span
                    onClick={cambiarVisibilidadConfirmacionClave}
                    className='absolute inset-y-0 right-0 flex items-center pr-3 pb-2 cursor-pointer'>
                    {mostrarConfirmacionClave ? (
                      <FaEyeSlash className='text-[#FF6652] text-[22px]' />
                    ) : (
                      <FaEye className='text-[#FF6652] text-[22px]' />
                    )}{' '}
                    {/* Eye icons */}
                  </span>
                </div>
                <MensajeValidacion error={confirmarClaveError} />
              </div>
            </div>
            <MensajeError error={errorGeneral} />
            <div className='w-full flex justify-center py-6'>
              <button
                onClick={siguientePaso}
                className='flex text-white font-medium items-center bg-black p-2 px-3 rounded-[10px]'>
                Siguiente
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </button>
            </div>
          </div>
        </>
      )}
      {pasoActual === '2' && (
        <>
          <div className='mt-12 px-10 w-full flex flex-col justify-center items-center text-[16px] lg:text-[16px]'>
            <div className='w-full py-2'>
              <input
                value={tipoNegocio}
                maxLength={tipoNegocioMaxLength}
                onChange={e => {
                  setTipoNegocio(e.target.value);
                  if (e.target.value !== '') {
                    setTipoNegocioError('valid');
                  } else {
                    setTipoNegocioError('El tipo de Negocio es requerido.');
                  }
                }}
                className={`rounded-[10px] py-3 px-3 w-full mb-3 bg-gray-100 ${
                  tipoNegocioError !== '' &&
                  tipoNegocioError !== 'valid' &&
                  'bg-red-200'
                }`}
                placeholder='(*) Tipo de Negocio'
              />
              <MensajeValidacion error={tipoNegocioError} />
            </div>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <select
                value={provincia}
                onChange={e => {
                  setProvincia(e.target.value);
                }}
                className='py-4 px-2 rounded-[10px] bg-gray-100'>
                <option value='' disabled selected>
                  Provincia
                </option>
                {provincias.map(provincia => (
                  <option key={provincia.id} value={provincia.id}>
                    {provincia.nombre}
                  </option>
                ))}
              </select>
              <div></div>
              <MensajeValidacion error={provinciaError} />
            </div>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <select
                value={canton}
                disabled={provincia === ''}
                onChange={e => setCanton(e.target.value)}
                className='py-4 px-2 rounded-[10px] bg-gray-100'>
                <option value='' disabled selected>
                  Cantón
                </option>
                {cantones.map(canton => (
                  <option key={canton.id} value={canton.id}>
                    {canton.nombre}
                  </option>
                ))}
              </select>
              <div></div>
              <MensajeValidacion error={cantonError} />
            </div>
            <div className='w-full grid grid-cols-2 gap-4 py-2'>
              <select
                value={distrito}
                disabled={canton === ''}
                onChange={e => setDistrito(e.target.value)}
                className='py-4 px-2 rounded-[10px] bg-gray-100'>
                <option value='' disabled selected>
                  Distrito
                </option>
                {distritos.map(distrito => (
                  <option key={distrito.id} value={distrito.id}>
                    {distrito.nombre}
                  </option>
                ))}
              </select>
              <div></div>
              <MensajeValidacion error={distritoError} />
            </div>
            <div className='w-full py-2'>
              <textarea
                value={direccionExacta}
                rows={4}
                className={`rounded-[40px] leading-none py-6 px-6 w-full mb-12 resize-none bg-gray-100 ${
                  direccionExactaError !== '' &&
                  direccionExactaError !== 'valid' &&
                  'bg-red-200'
                }`}
                placeholder='Dirección Exacta'
                onChange={e => {
                  if (e.target.value !== '') {
                    setDireccionExactaError('valid');
                  } else {
                    setDireccionExactaError(
                      'La dirección exacta es requerida.',
                    );
                  }

                  handleDireccionExactaChange(e);
                }}
              />
              <p
                className='text-[14px] -mt-[80px] z-10 text-right mr-8 mb-8'
                style={{
                  color:
                    direccionExactaMaxLength - direccionExacta.length >= 50
                      ? 'black'
                      : 'red',
                }}>
                {direccionExacta.length}/
                {direccionExactaMaxLength - direccionExacta.length}
              </p>
              <MensajeValidacion error={direccionExactaError} />
            </div>
            <MensajeError error={errorGeneral} />
            <div className='w-full flex justify-center py-6'>
              <button
                onClick={crearCuenta}
                className='flex text-white font-medium items-center bg-black p-4 rounded-[10px]'>
                Crear Cuenta
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </button>
            </div>
          </div>
        </>
      )}
      {pasoActual === '3' && (
        <div className='flex flex-col justify-center items-center mt-[350px] font-semibold font-urbanist'>
          {errorResponse && errorResponse.length > 0 && (
            <div className='rounded-lg bg-red-100 p-6 my-10 w-2/4 flex flex-col items-center'>
              <div className='flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600 mr-3'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
                <p className='text-red-600 font-bold'>¡Ups! Algo salió mal.</p>
              </div>
              <div className='mt-4 text-left text-red-600'>
                <ul className='list-disc pl-6'>
                  {errorResponse.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
              <div className='w-full mt-6 flex flex-row'>
                <button
                  onClick={() => setPasoActual('1')}
                  className='bg-red-600 text-white font-medium p-4  rounded-[10px]'>
                  Verificar Datos
                </button>
                {hasIniciarSesion && (
                  <button className='flex text-white font-medium items-center bg-black p-4 rounded-[10px] px-16 ml-4'>
                    Iniciar Sesión
                    <img className='ml-2 w-[55px]' src={IcoArrow} />
                  </button>
                )}
              </div>
            </div>
          )}
          {errorResponse.length === 0 && procesando && (
            <div
              className={
                'w-[250px] rounded-[10px] bg-white py-5 flex items-center justify-center'
              }>
              <p
                className={`transition-all duration-500 ease-in-out transform ${
                  animation
                    ? 'opacity-0 translate-x-6'
                    : 'opacity-100 translate-x-0'
                }`}>
                Creando Cuenta...
              </p>
            </div>
          )}
          {errorResponse.length === 0 && finalizado && (
            <div className='w-[250px] rounded-[10px] bg-white py-5 flex items-center justify-center'>
              <div className='mr-3'>
                <FaUserCheck className='h-6 w-6 text-[#FF6652] animate-pulse' />
              </div>
              <p>Cuenta Creada</p>
            </div>
          )}
          <div className='w-full flex justify-center py-6 font-urbanist mt-52'>
            {finalizado && (
              <button
                onClick={navigate('/login')}
                className='flex text-white font-medium items-center bg-black p-4 rounded-[10px] px-16'>
                Ingresar
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormularioRegistro;
