import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {api} from '../config';

export const PlazaAPI = {
  agregar: async function (request) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: '/api/v1/Plaza',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  obtenerResumen: async function () {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const empresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);

    const response = await api.request({
      url: `/api/v1/Plaza/${empresa}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  obtenerDetalle: async function (id) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/Plaza/Detalle/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  obtenerPlazasActivas: async function () {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const empresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);

    const response = await api.request({
      url: `/api/v1/Plaza/${empresa}/plazas-activas`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  obtenerVacantes: async function () {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const empresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);

    const response = await api.request({
      url: `/api/v1/Plaza/${empresa}/vacantes`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  obtenerAplicantes: async function () {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const empresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);

    const response = await api.request({
      url: `/api/v1/Plaza/${empresa}/aplicantes`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  eliminar: async function (id) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/Plaza/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
};
