import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import Routes from './Routes.jsx';
import {AuthProvider} from './context/AuthContext.jsx';
import * as Sentry from '@sentry/react';
import {Provider} from 'react-redux';
import store from './store/store';
import {ToastContainer} from 'react-toastify';
import {ThemeProvider, createTheme} from '@mui/material';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary.js';

Sentry.init({
  dsn: 'https://ba5489b42533af95a1a037bb6376a407@o4506157292060672.ingest.sentry.io/4506157293764608',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme({
  typography: {
    fontFamily: ['urbanist'].join(','),
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='dark'
      transition:Bounce
    />
  </React.StrictMode>,
);
