import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import {CuentaAPI} from '../services/cuenta';

const schema = yup.object().shape({
  tipo: yup.string().required('Tipo is required'),
  identificacion: yup.string().required('Identificación is required'),
  nombreEmpresa: yup.string().required('Nombre de la Empresa is required'),
  nombreRepresentante: yup
    .string()
    .required('Nombre del Representante is required'),
  apellidosRepresentante: yup
    .string()
    .required('Apellidos del Representante are required'),
  telefono: yup.string().required('Teléfono is required'),
  tipoNegocio: yup.string().required('Tipo de Negocio is required'),
  direccion: yup.string().required('Dirección is required'),
});

const Configuracion = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // Simulate fetching data from a server
    const fetchData = async () => {
      // Replace this with your actual API call
      const response = await CuentaAPI.obtener();
      const data = await response;

      // Patch the form with the fetched data
      reset(data);
    };

    fetchData();
  }, [reset]);

  const onSubmit = data => {
    //console.log(data);
  };

  return (
    <MainWrapper>
      <div className='flex flex-row'>
        <h1 className='text-3xl font-medium mr-14'>Configuración de Cuenta</h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      <div className='flex flex-col max-w-[850px] min-w-[800px] mt-10 bg-[#dce5e7] p-10 rounded-[20px]'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Identificación</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('identificacion')}
            />
            {errors.identificacion && <p>{errors.identificacion.message}</p>}
          </div>
          <div>
            <label>Nombre de la Empresa</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('nombreEmpresa')}
            />
            {errors.nombreEmpresa && <p>{errors.nombreEmpresa.message}</p>}
          </div>
          <div>
            <label>Nombre del Representante</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('nombreRepresentante')}
            />
            {errors.nombreRepresentante && (
              <p>{errors.nombreRepresentante.message}</p>
            )}
          </div>
          <div>
            <label>Apellidos del Representante</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('apellidosRepresentante')}
            />
            {errors.apellidosRepresentante && (
              <p>{errors.apellidosRepresentante.message}</p>
            )}
          </div>
          <div>
            <label>Teléfono</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('telefono')}
            />
            {errors.telefono && <p>{errors.telefono.message}</p>}
          </div>
          <div>
            <label>Tipo de Negocio</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('tipoNegocio')}
            />
            {errors.tipoNegocio && <p>{errors.tipoNegocio.message}</p>}
          </div>
          <div>
            <label>Dirección</label>
            <input
              disabled
              className='my-2 rounded-[10px] p-3 w-full bg-gray-100 cursor-not-allowed'
              {...register('direccion')}
            />
            {errors.direccion && <p>{errors.direccion.message}</p>}
          </div>
        </form>
      </div>
    </MainWrapper>
  );
};

export default Configuracion;
