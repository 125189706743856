import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import MensajeValidacion from '../validacion/MensajeValidacion';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import MensajeError from '../common/MensajeError';
import IcoArrow from '../../assets/ico-arrow.png';
import {LoginAPI} from '../../services/auth';
import * as Sentry from '@sentry/react';
import {useDispatch} from 'react-redux';
import {setToken, setUser} from '../../store/userSlice';
import './LoginForm.css';

import LogoBotonImg from '../../assets/registro/logo-boton.png';

const LoginForm = () => {
  //use
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //states
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [clave, setClave] = useState('');
  const [claveError, setClaveError] = useState('');
  const [mostrarClave, setMostrarClave] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState('');
  const [procesando, setProcesando] = useState(false);
  const [errorResponse, setErrorResponse] = useState([]);

  const cambiarVisibilidadClave = () => {
    setMostrarClave(!mostrarClave);
  };

  const verificarClave = claveValue => {
    if (!claveValue || claveValue === '') {
      setClaveError('La clave es requerida.');
    } else {
      setClaveError('valid');
    }
  };

  const validateEmail = email => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const esValido = emailPattern.test(email);
    if (!esValido) {
      setEmailError('Debe ingresar un correo electrónico válido.');
    } else {
      setEmailError('valid');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setErrorGeneral('');
    setErrorResponse([]);

    validateEmail(email);
    verificarClave(clave);

    if (emailError === 'valid' && claveError === 'valid') {
      setProcesando(true);

      var credenciales = {
        gToken: 'gtoken',
        email: email?.toLowerCase().trim(),
        password: clave.trim(),
      };

      LoginAPI.validar(credenciales)
        .then(response => {
          setProcesando(false);
          if (response.errors && response.errors.length > 0) {
            setErrorResponse(response.errors);
          } else {
            setProcesando(false);
            if (response && response.token) {
              //Guardar token en redux
              dispatch(setToken(response.token?.accessToken));
              dispatch(setUser(response));
              navigate('/');
            } else {
              const mensajeError = [
                'Se ha producido un error al verificar credenciales. Por favor, inténtalo de nuevo.',
              ];
              setErrorResponse(mensajeError);
            }
          }
        })
        .catch(error => {
          setProcesando(false);
          if (error.response?.status === 401) {
            setErrorResponse(['Usuario o contraseña incorrectos.']);
            return;
          }

          const mensajeError = [
            'Se ha producido un error al intentar comunicarse con el servidor. Por favor, inténtalo de nuevo.',
          ];
          setErrorResponse(mensajeError);
          Sentry.captureException(error);
        });
    } else {
      setErrorGeneral('Debe verificar los campos requeridos para continuar.');
    }
  };

  const handleEmailChange = e => {
    setEmailError('');
    const emailValue = e.target.value;
    setEmail(emailValue);
    validateEmail(emailValue);
  };

  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100'>
      <div className='max-w-md w-full space-y-8'>
        <div className='flex justify-center'>
          <img className='mb-10' src={LogoBotonImg} />
        </div>
        <div>
          <h2 className='text-4xl leading-[88%] text-center my-5 mt-10'>
            Bienvenido a tu <span className='font-semibold'>cuenta</span>
          </h2>
        </div>
        <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
          {!procesando && (
            <div className={!procesando ? 'fade-in' : 'fade-out'}>
              <div>
                <label htmlFor='email' className='sr-only'>
                  Correo Electrónico
                </label>
                <input
                  id='email'
                  value={email}
                  type='email'
                  autoComplete='email'
                  onChange={e => handleEmailChange(e)}
                  className={`rounded-[60px] py-3 px-3 w-full placeholder-gray-500 text-gray-900 mb-3 ${
                    emailError !== '' && emailError !== 'valid' && 'bg-red-200'
                  }`}
                  placeholder='(*) Correo Electrónico'
                />
                <MensajeValidacion error={emailError} />
              </div>
              <div className='mt-2'>
                <label htmlFor='password' className='sr-only'>
                  Password
                </label>
                <div className='relative'>
                  <input
                    type={mostrarClave ? 'text' : 'password'}
                    className={`rounded-[60px] py-3 px-3 w-full mb-3 placeholder-gray-500 text-gray-900 ${
                      claveError !== '' &&
                      claveError !== 'valid' &&
                      'bg-red-200'
                    } ${mostrarClave || clave.length === 0 ? '' : 'password-input'}`}
                    value={clave}
                    autoComplete='current-password'
                    placeholder='(*) Contraseña'
                    onChange={e => {
                      setClave(e.target.value);
                      if (e.target.value !== '') {
                        setClaveError('valid');
                      } else {
                        setClaveError('Contraseña es requerida.');
                      }
                    }}
                  />
                  <span
                    onClick={cambiarVisibilidadClave}
                    className='absolute inset-y-0 right-0 flex items-center pr-3 pb-2 cursor-pointer'>
                    {mostrarClave ? (
                      <FaEyeSlash className='text-[#FF6652] text-[22px]' />
                    ) : (
                      <FaEye className='text-[#FF6652] text-[22px]' />
                    )}{' '}
                    {/* Eye icons */}
                  </span>
                </div>
                <div className='flex items-end justify-end'>
                  <a className='underline' href='/recuperar-clave'>
                    ¿Has olvidado tu contraseña?
                  </a>
                </div>
                <MensajeValidacion error={claveError} />
              </div>
            </div>
          )}
          <MensajeError error={errorGeneral} />
          {errorResponse && errorResponse.length > 0 && (
            <div className='rounded-lg bg-red-100 p-6 my-10 w-2/4 flex flex-col items-center w-full'>
              <div className='flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600 mr-3'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
                <p className='text-red-600 font-bold'>¡Ups! Algo salió mal.</p>
              </div>
              <div className='mt-4 text-left text-red-600'>
                <ul className='list-disc pl-6'>
                  {errorResponse.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div>
            {!procesando && (
              <button
                type='submit'
                className='group relative w-full justify-center flex text-white font-medium items-center bg-[#FF6652] p-4 rounded-[60px]'>
                Ingresar
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </button>
            )}
            <div
              className={`w-full rounded-[60px] bg-white py-5 flex items-center pointer-events-none justify-center ${
                procesando ? 'fade-in' : 'fade-out'
              }`}>
              <p
                className={
                  'transition-all duration-500 ease-in-out transform animate-bounce'
                }>
                Verificando Cuenta...
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
