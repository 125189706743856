import React from 'react';
import {Link, useParams} from 'react-router-dom';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';

const RequestError = () => {
  return (
    <MainWrapper>
      <div className='flex flex-row'>
        <h1 className='text-3xl font-medium mr-14'></h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      <div className='flex h-[100dvh] w-full flex-col items-center justify-center bg-gray-100 rounded-lg mt-6 px-4'>
        <div className='mx-auto w-full max-w-md space-y-6 rounded-lg bg-white p-6 shadow-lg'>
          <div className='space-y-2 text-center'>
            <TriangleAlertIcon className='mx-auto h-12 w-12 text-red-500' />
            <h1 className='text-3xl font-bold tracking-tight'>
              ¡Ups! ¡Algo salió mal!
            </h1>
            <p className='text-gray-500 dark:text-gray-400'>
              Lo sentimos, pero ha ocurrido un error inesperado. Por favor,
              inténtelo de nuevo más tarde o contacte al soporte si el problema
              persiste.
            </p>
          </div>
          <div className='flex justify-center'>
            <a
              href='/'
              className='inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-6 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300'>
              Ir al Inicio
            </a>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

function TriangleAlertIcon(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3' />
      <path d='M12 9v4' />
      <path d='M12 17h.01' />
    </svg>
  );
}

export default RequestError;
