import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {api} from '../config';

export const PerfilAPI = {
  obtenerSugerencia: async function (keyword) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/Perfil/${keyword}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  agregar: async function (request) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/Perfil`,
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
};
