import React from 'react';

const MensajeError = props => {
  const {error} = props;
  return (
    <>
      {error && (
        <div className='w-full flex justify-center items-center flex-row bg-[#C9D3DB] rounded-[60px]'>
          <div className='text-red-800 w-[600px] py-4 flex flex-row justify-center items-center'>
            <svg width='24' height='24' viewBox='0 0 16 16' fill='none'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M8.00019 1.39138C11.644 1.39138 14.6089 4.35625 14.6089 8.00008C14.6089 11.6439 11.644 14.6088 8.00019 14.6088C4.35636 14.6088 1.3915 11.6439 1.3915 8.00008C1.3915 4.35625 4.35636 1.39138 8.00019 1.39138M8 16C12.4111 16 16 12.4111 16 8C16 3.58887 12.4111 -5.96046e-07 8 -5.96046e-07C3.58887 -5.96046e-07 -5.96046e-07 3.58887 -5.96046e-07 8C-5.96046e-07 12.4111 3.58887 16 8 16'
                fill='rgb(153 27 27 / var(--tw-text-opacity))'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M8.61548 4.48758C8.61548 4.10339 8.30402 3.79193 7.91983 3.79193V3.79193C7.53563 3.79193 7.22417 4.10338 7.22417 4.48758L7.22417 9.15054C7.22417 9.53474 7.53563 9.84619 7.91983 9.84619V9.84619C8.30402 9.84619 8.61548 9.53474 8.61548 9.15054V4.48758Z'
                fill='rgb(153 27 27 / var(--tw-text-opacity))'></path>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M8.61548 11.612C8.61548 11.2278 8.30402 10.9163 7.91983 10.9163V10.9163C7.53563 10.9163 7.22417 11.2278 7.22417 11.612V11.612C7.22417 11.9962 7.53563 12.3076 7.91983 12.3076V12.3076C8.30402 12.3076 8.61548 11.9962 8.61548 11.612V11.612Z'
                fill='rgb(153 27 27 / var(--tw-text-opacity))'></path>
            </svg>
            <span className='ml-2 text-black'>{error}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default MensajeError;
