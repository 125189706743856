import React from 'react';
import Sidebar from '../components/main/Sidebar';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import Footer from '../components/common/Footer';

const Dashboard = props => {
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex bg-[#fefefe] flex-1'>
        <Sidebar />
        <div className='p-8 w-full'>
          <div className='flex flex-row'>
            <h1 className='text-3xl font-medium mr-14'>
              Dash<span className='font-semibold'>board</span>
            </h1>
            <WidgetCantidadPlazas />
            <WidgetPlazasActivas />
            <WidgetVacantes />
          </div>
          {/* Other dashboard content */}
        </div>
      </div>
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default Dashboard;
