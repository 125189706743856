import React from 'react';
import {Ouroboro} from 'react-spinners-css';

function LoadingIndicator() {
  return (
    <div className='loading-indicator text-center'>
      <Ouroboro color='#ff6652' />
      <p>Cargando información...</p>
    </div>
  );
}

export default LoadingIndicator;
