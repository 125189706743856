import React from 'react';

const BaseWidget = props => {
  const {titulo, contador} = props;
  return (
    <>
      <div className='mx-2 bg-[#dce5e7] w-[200px] rounded-[20px] px-1 justify-center items-center font-bold flex flex-row h-[50px]'>
        <div className='flex-[60%] ml-4'>
          <p>{titulo}</p>
        </div>
        <div className='flex w-[40px] justify-center items-center text-center bg-[#fefefe] h-[40px] rounded-[20px]'>
          <p className='font-normal flex justify-center items-center text-center'>
            {contador}
          </p>
        </div>
      </div>
    </>
  );
};

export default BaseWidget;
