import React, {useState, useEffect} from 'react';
import mapboxgl from 'mapbox-gl';
import markerImg from '../../assets/pin.png';
import IcoArrow from '../../assets/ico-arrow.png';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';

const SelectorUbicacion = ({onBack, onSubmit}) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoiYXNhbGdhZG9zIiwiYSI6ImNscDRkMHRzODB1dWsyc3AyMG83ejM5OHcifQ.qmOa_ysUgh8jSwMepk3gNw';

  const [jsonData, setJsonData] = useState(null);
  const [provinciaNombre, setProvinciaNombre] = useState('');
  const [provincias, setProvincias] = useState([]);
  const [provincia, setProvincia] = useState('');
  const [cantonNombre, setCantonNombre] = useState('');
  const [cantones, setCantones] = useState([]);
  const [canton, setCanton] = useState('');
  const [distritoNombre, setDistritoNombre] = useState('');
  const [distritos, setDistritos] = useState([]);
  const [distrito, setDistrito] = useState('');
  const direccionExactaMaxLength = 500;
  const [direccionExacta, setDireccionExacta] = useState('');
  const [direccionExactaError, setDireccionExactaError] = useState('');
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: 9.9356,
    longitude: -84.0916,
  });

  const notifyError = message => toast.error(message);

  const handleDireccionExactaChange = event => {
    if (event.target.value.length <= direccionExactaMaxLength) {
      setDireccionExacta(event.target.value);
    }
  };

  const initializeMap = center => {
    try {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center,
        zoom: 16,
      });

      map.on('load', () => {
        const el = document.createElement('div');
        el.className = 'custom-marker';
        el.style.backgroundImage = `url(${markerImg})`;
        el.style.width = '30px';
        el.style.height = '30px';

        const pin = new mapboxgl.Marker(el, {draggable: true})
          .setLngLat(center)
          .addTo(map);

        setMap(map);
        setMarker(pin);
      });
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  };

  const obtenerUbicacion = distritoSeleccionado => {
    var query = `${distritoSeleccionado},${cantonNombre},${provinciaNombre},Costa Rica`;

    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=CR&access_token=${mapboxgl.accessToken}`,
      )
      .then(response => {
        var data = response.data;
        if (data.features.length > 0) {
          var coordinates = data.features[0].center;

          if (!map) {
            initializeMap(coordinates);
          } else {
            // Update map center
            map.flyTo({
              center: coordinates,
              zoom: 12,
            });
            // Update marker coordinates
            marker.setLngLat(coordinates);
          }
        } else {
          if (!map) {
            initializeMap([
              selectedLocation.latitude,
              selectedLocation.longitude,
            ]);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
        Sentry.captureException(error);
        if (!map) {
          initializeMap([
            selectedLocation.latitude,
            selectedLocation.longitude,
          ]);
        }
      });
  };

  //Carga provincias
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/geodata.json');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setJsonData(data);

        if (data) {
          const objProvincias = Object.keys(data.provincias).map(key => ({
            id: key,
            nombre: data.provincias[key].nombre,
          }));

          setProvincias(objProvincias);
        }
      } catch (error) {
        console.error('Error fetching the JSON file:', error);
      }
    };

    fetchData();
  }, []);

  //Carga cantones
  useEffect(() => {
    if (provincia !== '') {
      const cantonesPorProvincia = jsonData.provincias[provincia];
      if (cantonesPorProvincia) {
        setProvinciaNombre(cantonesPorProvincia.nombre);
        const objCantones = Object.keys(cantonesPorProvincia.cantones).map(
          key => ({
            id: key,
            nombre: cantonesPorProvincia.cantones[key].nombre,
          }),
        );
        setCantones(objCantones);
        if (objCantones.length > 0) {
          setCantonNombre(objCantones[0].nombre);
          setCanton(objCantones[0].id);
        }
      }
    }
  }, [provincia]);

  //cargo distritos
  useEffect(() => {
    if (canton !== '') {
      const distritosPorProvincia =
        jsonData.provincias[provincia]?.cantones[canton];
      if (distritosPorProvincia) {
        setCantonNombre(distritosPorProvincia.nombre);
        const objDistrito = Object.keys(distritosPorProvincia.distritos).map(
          key => ({
            id: key,
            nombre: distritosPorProvincia.distritos[key],
          }),
        );
        setDistritos(objDistrito);
        if (objDistrito.length > 0) {
          setDistritoNombre(objDistrito[0].nombre);
          setDistrito(objDistrito[0].id);
        }
      }
    }
  }, [cantonNombre, canton]);

  useEffect(() => {
    if (distrito !== '') {
      const distritoSeleccionado = distritos.filter(x => {
        return x.id === distrito;
      });
      setDistritoNombre(distritoSeleccionado[0].nombre);
    }
  }, [distrito]);

  useEffect(() => {
    if (distrito !== '') {
      obtenerUbicacion(distritoNombre);
    }
  }, [distritoNombre]);

  useEffect(() => {
    if (marker) {
      marker.setLngLat([selectedLocation.longitude, selectedLocation.latitude]);
    }
  }, [marker, selectedLocation]);

  const saveLocation = () => {
    if (!provincia || !canton || !distrito || !direccionExacta) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      notifyError('Todos los campos son requeridos.');
      return;
    }

    if (map && marker) {
      const {lng, lat} = marker.getLngLat();
      onSubmit({
        provincia: provincia,
        provinciaNombre: provinciaNombre,
        canton: canton,
        cantonNombre: cantonNombre,
        distrito: distrito,
        distritoNombre: distritoNombre,
        direccionExacta,
        lat,
        lng,
      });
    }
  };

  return (
    <form className='w-full'>
      <div className='mb-10 rounded-[60px]'>
        <h2 className='font-semibold'>Ubicación de Plaza</h2>
        <p className='mt-1'>Proporciona ubicación exacta de la plaza.</p>
      </div>
      <div className='grid grid-cols-3'>
        <div className='flex flex-col'>
          <label htmlFor='nombre'>Provincia:</label>
          <select
            id='provincia'
            name='provincia'
            value={provincia}
            onChange={e => {
              setProvincia(e.target.value);
            }}
            className='my-2 mx-1 rounded-[10px] p-3 w-[90%]'>
            <option value='' disabled selected>
              Seleccione una provincia
            </option>
            {provincias.map(provincia => (
              <option key={provincia.id} value={provincia.id}>
                {provincia.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col'>
          <label htmlFor='nombre'>Canton:</label>
          <select
            id='canton'
            name='canton'
            value={canton}
            disabled={provincia === ''}
            onChange={e => setCanton(e.target.value)}
            className='my-2 mx-1 rounded-[10px] p-3 w-[90%]'>
            <option value='' disabled selected>
              Seleccione un canton
            </option>
            {cantones.map(canton => (
              <option key={canton.id} value={canton.id}>
                {canton.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-col'>
          <label htmlFor='nombre'>Distrito:</label>
          <select
            id='distrito'
            name='distrito'
            value={distrito}
            disabled={canton === ''}
            onChange={e => setDistrito(e.target.value)}
            className='my-2 rounded-[10px] mx-1 p-3 w-[90%]'>
            <option value='' disabled selected>
              Seleccion un distrito
            </option>
            {distritos.map(distrito => (
              <option key={distrito.id} value={distrito.id}>
                {distrito.nombre}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='flex flex-col mt-4'>
        <label htmlFor='nombre'>Dirección exacta:</label>
        <textarea
          value={direccionExacta}
          rows={4}
          className={`rounded-[10px] mt-2 leading-none py-3 px-2 w-full mb-12 resize-none ${
            direccionExactaError !== '' &&
            direccionExactaError !== 'valid' &&
            'bg-red-200'
          }`}
          placeholder='Ingrese la dirección exacta del lugar'
          onChange={e => {
            if (e.target.value !== '') {
              setDireccionExactaError('valid');
            } else {
              setDireccionExactaError('La dirección exacta es requerida.');
            }

            handleDireccionExactaChange(e);
          }}
        />
        <p
          className='text-[14px] -mt-[80px] z-10 text-right mr-8 mb-8'
          style={{
            color:
              direccionExactaMaxLength - direccionExacta.length >= 50
                ? 'black'
                : 'red',
          }}>
          {direccionExacta.length}/
          {direccionExactaMaxLength - direccionExacta.length}
        </p>
      </div>
      <div className='relative flex flex-col'>
        <label htmlFor='nombre'>
          Ubicación en el mapa:{' '}
          <span className='text-sm'>
            (Ayudará a los usuarios visualizar de manera correcta la ubicación)
          </span>
        </label>
        <div
          id='map'
          className='mt-2 h-64 mb-4 relative overflow-hidden rounded-[10px]'></div>
      </div>

      <div className='w-full flex justify-end py-6'>
        <button onClick={onBack} className='flex p-4 font-medium items-center'>
          Atrás
        </button>
        <button
          type='button'
          onClick={saveLocation}
          className='flex text-white font-medium items-center bg-gray-900 p-2 px-3 rounded-[60px]'>
          Validar datos
          <img className='ml-2 w-[55px]' src={IcoArrow} />
        </button>
      </div>
    </form>
  );
};

export default SelectorUbicacion;
