import React from 'react';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import FormularioContactos from '../components/contactos/FormularioContactos';

const Contactos = () => {
  return (
    <MainWrapper>
      <div className='flex flex-row'>
        <h1 className='text-3xl font-medium mr-14'>
          Mantenimiento de <span className='font-semibold'>Contactos</span>
        </h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      {/* Other dashboard content */}
      <FormularioContactos />
    </MainWrapper>
  );
};

export default Contactos;
