import React, {useState, useEffect} from 'react';
import SplitStringWithHyphen from '../../../utils/StringUtils';
import {ContactosAPI} from '../../../services/contactos';
import LOCAL_STORAGE_KEYS from '../../../constants/localStorageKeys';
import {toast} from 'react-toastify';
import * as Sentry from '@sentry/react';
import {BsPlus} from 'react-icons/bs';
import Modal from '../../global/Modal';
import NuevoContacto from '../../contactos/NuevoContacto';

const Contactos = ({value, onChange}) => {
  const [cargandoContactos, setCargandoContactos] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [visualizarForm, setVisualizarForm] = useState(false);

  const notifyError = message => toast.error(message);

  const obtenerContactos = async () => {
    try {
      const response = await ContactosAPI.obtener(
        sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
      );
      setContacts(response);
    } catch (error) {
      Sentry.captureException(error);
      notifyError('Error al obtener contactos.');
    }
  };

  const handleOnClose = () => {
    setVisualizarForm(false);
    obtenerContactos();
  };

  useEffect(() => {
    const fetchContacts = async () => {
      setCargandoContactos(true);
      try {
        const response = await ContactosAPI.obtener(
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
        );
        setContacts(response);
        setCargandoContactos(false);
      } catch (error) {
        Sentry.captureException(error);
        notifyError('Error al obtener contactos.');
        setCargandoContactos(false);
      }
    };

    fetchContacts();
  }, []);

  return (
    <>
      <div className='flex flex-row items-center mt-2'>
        <label htmlFor='contacto'>Contacto (*):</label>
        <button
          type='button'
          onClick={() => setVisualizarForm(true)}
          className='flex flex-row items-center bg-gray-900 rounded-[10px] ml-4 text-white p-1 text-xs'>
          <BsPlus className='h-3 w-3' />
          Añadir contacto
        </button>
      </div>

      <select
        className='my-2 rounded-[10px] p-3 w-2/3'
        id='contacto'
        value={value}
        onChange={e =>
          onChange(
            e.target.value,
            e.target.options[e.target.selectedIndex].text,
          )
        }>
        <option value=''>
          {cargandoContactos
            ? 'Cargando contactos ...'
            : 'Selecciona un contacto'}
        </option>
        {contacts.map(contact => (
          <option
            key={contact.idEmpresaContacto}
            value={contact.idEmpresaContacto}>
            {contact.nombre} ({SplitStringWithHyphen(contact.telefono)})
          </option>
        ))}
      </select>
      <Modal isOpen={visualizarForm} onClose={handleOnClose}>
        <NuevoContacto closeForm={handleOnClose} />
      </Modal>
    </>
  );
};

export default Contactos;
