import React, {useState, useEffect} from 'react';

import bgCuentaImg from '../assets/registro/lumy-creacion-cuenta.png';
import LogoBotonImg from '../assets/registro/logo-boton.png';
import FormularioRegistro from '../components/registro/FormularioRegistro';

const Registro = () => {
  return (
    <>
      <div className='min-h-screen bg-[#DADADA] h-auto'>
        <button
          className='rounded-b-[20px] bg-black w-[150px] h-[70px] flex justify-center items-center absolute -top-1 left-[30px] 
        md:w-[125px] md:h-[45px] md:p-5 md:top-0 
        sm:w-[125px] sm:h-[45px] sm:p-5 sm:top-1 left-1 '>
          <img className='w-[125px]' src={LogoBotonImg} alt='Logo Lumy' />
        </button>
        <div className='grid grid-cols-3'>
          <div
            className='bg-white h-full bg-left bg-cover lg:bg-no-repeat lg:bg-left lg:bg-fixed lg:bg-contain md:hidden sm:hidden'
            style={{backgroundImage: `url(${bgCuentaImg})`}}>
            <div className='flex flex-row'>
              <p className='text-white text-5xl font-semibold mt-[150px] ml-[30px] leading-[88%]'>
                Trabajos <br></br> temporales<br></br>
                <span className='font-thin'>
                  Experiencias <br></br> permanentes
                </span>
              </p>
            </div>
          </div>
          <div className='col-span-2 sm:col-span-3 md:col-span-3 min-h-screen'>
            <FormularioRegistro />
          </div>
        </div>
      </div>
    </>
  );
};

export default Registro;
