import React, {useEffect, useRef} from 'react';
import mapboxgl from 'mapbox-gl';

// Set your Mapbox access token here
mapboxgl.accessToken =
  'pk.eyJ1IjoiYXNhbGdhZG9zIiwiYSI6ImNscDRkMHRzODB1dWsyc3AyMG83ejM5OHcifQ.qmOa_ysUgh8jSwMepk3gNw';

const StaticMap = ({coordinates}) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: coordinates,
      zoom: 12,
      // Disable map interactions
      interactive: false,
    });

    // Add a pin (marker) to the map
    new mapboxgl.Marker().setLngLat(coordinates).addTo(map);

    // Clean up on unmount
    return () => map.remove();
  }, [coordinates]);

  return (
    <div ref={mapContainerRef} style={{width: '400px', height: '200px'}} />
  );
};

export default StaticMap;
