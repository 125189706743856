import React from 'react';
import Sidebar from '../main/sidebar';
import Footer from '../common/Footer';

const MainWrapper = ({children}) => {
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex bg-[#fefefe] flex-1'>
        <Sidebar />
        <div className='p-8 w-full'>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default MainWrapper;
