import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {api} from '../config';

export const CuentaAPI = {
  crear: async function (cuenta) {
    const response = await api.request({
      url: '/api/v1/Empresa',
      method: 'POST',
      data: cuenta,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
      },
    });

    return response?.data;
  },
  obtenerInfoCuenta: async function (request) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: 'api/v1/Cuenta/ObtenerInformacion',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: 'Bearer ' + token,
      },
    });

    return response?.data;
  },
  cancelar: async function (request, token) {
    const response = await api.request({
      url: 'api/v1/Cuenta/Cancelar',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  validar: async function (request) {
    const response = await api.request({
      url: 'api/v1/Auth/Tokens',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

    return response?.data;
  },
  obtener: async function () {
    const idEmpresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: 'api/v1/Empresa/' + idEmpresa,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
};
