import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from '../../assets/registro/logo-boton.png';
import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {
  BsBuildingFillGear,
  BsList,
  BsTelephonePlusFill,
  BsBriefcaseFill,
} from 'react-icons/bs';
import {BsPersonLock} from 'react-icons/bs';
import {Avatar} from '@mui/material';

const Sidebar = () => {
  const [perfil, setPerfil] = useState({});

  useEffect(() => {
    const perfilObj = sessionStorage.getItem(LOCAL_STORAGE_KEYS.PERFIL);
    const perfil = JSON.parse(perfilObj);
    setPerfil(perfil);
    console.log(perfil);

    return () => {};
  }, []);

  const cerrarSesion = () => {
    sessionStorage.clear();
    window.location = '/login';
  };

  const obtenerAvatar = nombreEmpresa => {
    if (!nombreEmpresa) {
      return;
    }

    const primerasLetras = nombreEmpresa.substring(0, 3).toUpperCase();
    return (
      <Avatar
        sx={{width: 64, height: 64, bgcolor: '#1f2937'}}
        className='mr-3 mb-4'>
        {primerasLetras}
      </Avatar>
    );
  };
  const {pathname} = useLocation(); // Get the current URL pathname

  return (
    <>
      <div className='bg-[#f4f4f4] w-60 p-4 pt-0'>
        {/* Logo */}
        <div className='text-center mb-8'>
          <button
            className='w-[150px] h-[70px] flex justify-center items-center relative top-0 left-[10px] 
        md:w-[125px] md:h-[45px] md:p-5 md:top-0 
        sm:w-[125px] sm:h-[45px] sm:p-5 sm:top-1 '>
            <img className='w-[125px]' src={logo} alt='Logo Lumy' />
          </button>
        </div>

        {/* Profile section */}
        {perfil && (
          <div className='flex flex-col items-center'>
            {perfil.profilePhoto && (
              <img
                className='w-16 h-16 rounded-full mx-auto mb-4'
                src={`https://lumy-app.s3.amazonaws.com/${perfil.profilePhoto}`}></img>
            )}
            {!perfil.profilePhoto && obtenerAvatar(perfil.nombreEmpresa)}
            <div className='flex items-center gap-2 mb-8'>
              <span className='text-xl font-bold'>{perfil.nombreEmpresa}</span>
            </div>
            <nav className='space-y-4 items-start'>
              <Link
                to='/plazas'
                className={`flex items-center gap-3 hover:underline ${
                  pathname === '/plazas' ? 'font-bold' : ''
                }`}>
                <BsList className='h-5 w-5' />
                Mis Plazas
              </Link>
              <Link
                to='/contactos'
                className={`flex items-center gap-3 hover:underline ${
                  pathname === '/contactos' ? 'font-bold' : ''
                }`}>
                <BsTelephonePlusFill className='h-5 w-5' />
                Contactos
              </Link>
              <Link
                to='/configuracion'
                className={`flex items-center gap-3 hover:underline ${
                  pathname === '/configuracion' ? 'font-bold' : ''
                }`}
                prefetch={false}>
                <BsBuildingFillGear className='h-5 w-5' />
                Configuración
              </Link>
              <a
                onClick={cerrarSesion}
                className='flex items-center gap-3 hover:underline cursor-pointer'>
                <BsPersonLock className='h-5 w-5' />
                Cerrar Sesión
              </a>
            </nav>
          </div>
        )}
        {/* Menu options */}
        <div className='mt-10 text-center text-black font-semibold'>
          <Link
            to='/nueva-plaza'
            className={`bg-[#FF6652] p-4 rounded-[60px] mt-10 text-white font-semibold flex justify-center items-center ${
              pathname === '/nueva-plaza' ? 'font-bold' : ''
            }`}>
            <BsBriefcaseFill className='h-5 w-5 mr-2' />
            Nueva Plaza
          </Link>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
