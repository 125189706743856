import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import MensajeValidacion from '../validacion/MensajeValidacion';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import MensajeError from '../common/MensajeError';
import IcoArrow from '../../assets/ico-arrow.png';
import * as Sentry from '@sentry/react';
import {useDispatch} from 'react-redux';
import './ConfirmarClaveForm.css';

import LogoBotonImg from '../../assets/registro/logo-boton.png';
import {LoginAPI} from '../../services/auth';

const ConfirmarClaveForm = ({token}) => {
  const [clave, setClave] = useState('');
  const [claveError, setClaveError] = useState('');
  const [confirmacionClave, setconfirmacionClave] = useState('');
  const [confirmacionClaveError, setconfirmacionClaveError] = useState('');
  const [mostrarClave, setMostrarClave] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState('');
  const [procesando, setProcesando] = useState(false);
  const [errorResponse, setErrorResponse] = useState([]);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [mostrarMensajeExitoso, setMostrarMensajeExitoso] = useState(false);

  const verificaParametrosClave = claveValue => {
    if (claveValue !== '') {
      // Check for password length
      setIsLengthValid(claveValue.length >= 8);
      // Check for uppercase letter using regex
      setHasUppercase(/[A-Z]/.test(claveValue));
    }
  };

  const cambiarVisibilidadClave = () => {
    setMostrarClave(!mostrarClave);
  };

  const verificarClave = claveValue => {
    if (!claveValue || claveValue === '') {
      setClaveError('La clave es requerida.');
    } else {
      setClaveError('valid');
    }
  };

  const verificarConfirmacionClave = (confirmacionValue, claveValue) => {
    if (!confirmacionValue || confirmacionValue === '') {
      setconfirmacionClaveError('La confirmacion de contraseña es requerida.');
    } else {
      if (confirmacionValue === claveValue) {
        setconfirmacionClaveError('valid');
      } else {
        setconfirmacionClaveError('Las contraseñas no coinciden.');
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setErrorGeneral('');
    setErrorResponse([]);

    verificarClave(clave);
    verificarConfirmacionClave(confirmacionClave, clave);

    if (claveError === 'valid' && confirmacionClaveError === 'valid') {
      setProcesando(true);
      var request = {
        token: token,
        password: clave.trim(),
      };

      LoginAPI.cambiarClave(request)
        .then(response => {
          setProcesando(false);
          setMostrarMensajeExitoso(true);
          setClave('');
          setconfirmacionClave('');
        })
        .catch(error => {
          setProcesando(false);
          console.log(error.response);
          if (error.response?.status === 401) {
            setErrorResponse(['Usuario o contraseña incorrectos.']);
            return;
          }
          if (error.response?.data.error) {
            setErrorResponse([error.response?.data.error]);
            return;
          }

          const mensajeError = [
            'Se ha producido un error al intentar comunicarse con el servidor. Por favor, inténtalo de nuevo.',
          ];
          setErrorResponse(mensajeError);
          Sentry.captureException(error);
        });

      /*var credenciales = {
        gToken: 'gtoken',
        password: clave.trim(),
      };

      LoginAPI.validar(credenciales)
        .then(response => {
          setProcesando(false);
          if (response.errors && response.errors.length > 0) {
            setErrorResponse(response.errors);
          } else {
            setProcesando(false);
            if (response && response.token) {
              //Guardar token en redux
              dispatch(setToken(response.token?.accessToken));
              dispatch(setUser(response));
              navigate('/');
            } else {
              const mensajeError = [
                'Se ha producido un error al verificar credenciales. Por favor, inténtalo de nuevo.',
              ];
              setErrorResponse(mensajeError);
            }
          }
        })
        .catch(error => {
          setProcesando(false);
          if (error.response?.status === 401) {
            setErrorResponse(['Usuario o contraseña incorrectos.']);
            return;
          }

          const mensajeError = [
            'Se ha producido un error al intentar comunicarse con el servidor. Por favor, inténtalo de nuevo.',
          ];
          setErrorResponse(mensajeError);
          Sentry.captureException(error);
        });
        */
    } else {
      setErrorGeneral('Debe verificar los campos requeridos para continuar.');
    }
  };

  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100'>
      <div className='max-w-md w-full space-y-8'>
        <div className='flex justify-center'>
          <img className='mb-10' src={LogoBotonImg} />
        </div>
        <div>
          <h2 className='text-4xl leading-[88%] text-center my-5 mt-10'>
            Cambia tu <span className='font-semibold'>contraseña</span>
          </h2>
        </div>
        {!mostrarMensajeExitoso && (
          <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
            {!procesando && (
              <div className={!procesando ? 'fade-in' : 'fade-out'}>
                <div className='mt-2'>
                  <label htmlFor='password' className='sr-only'>
                    Password
                  </label>
                  <div className='relative'>
                    <input
                      type={mostrarClave ? 'text' : 'password'}
                      className={`rounded-[60px] py-3 px-3 w-full mb-3 placeholder-gray-500 text-gray-900 ${
                        claveError !== '' &&
                        claveError !== 'valid' &&
                        'bg-red-200'
                      } ${mostrarClave || clave.length === 0 ? '' : 'password-input'}`}
                      value={clave}
                      autoComplete='none'
                      placeholder='(*) Contraseña'
                      onChange={e => {
                        verificaParametrosClave(e.target.value);
                        setClave(e.target.value);
                        if (e.target.value !== '') {
                          setClaveError('valid');
                        } else {
                          setClaveError('Contraseña es requerida.');
                        }
                      }}
                    />
                    <span
                      onClick={cambiarVisibilidadClave}
                      className='absolute inset-y-0 right-0 flex items-center pr-3 pb-2 cursor-pointer'>
                      {mostrarClave ? (
                        <FaEyeSlash className='text-[#FF6652] text-[22px]' />
                      ) : (
                        <FaEye className='text-[#FF6652] text-[22px]' />
                      )}{' '}
                      {/* Eye icons */}
                    </span>
                  </div>
                  <MensajeValidacion error={claveError} />
                  <div className='mt-2 flex flex-col bg-gray-100 p-3 rounded-lg'>
                    <p>
                      {isLengthValid ? (
                        <span className='text-black'>
                          <span className='text-green-500'>&#10003; </span>Clave
                          contiene al menos 8 caracteres
                        </span>
                      ) : (
                        <span className='text-red-800'>
                          &#10007;{' '}
                          <span className='text-[#FF6652]'>
                            Clave debe contener al menos 8 caracteres
                          </span>
                        </span>
                      )}
                    </p>
                    <p className='mt-2'>
                      {hasUppercase ? (
                        <span className='text-black'>
                          <span className='text-green-500'>&#10003; </span>{' '}
                          Clave contiene al menos una mayúscula
                        </span>
                      ) : (
                        <span className='text-red-800'>
                          &#10007;{' '}
                          <span className='text-[#FF6652]'>
                            Clave debe contener al menos una mayúscula
                          </span>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className='mt-2'>
                  <label htmlFor='password' className='sr-only'>
                    Confirmar Contraseña
                  </label>
                  <div className='relative'>
                    <input
                      type={mostrarClave ? 'text' : 'password'}
                      className={`rounded-[60px] py-3 px-3 w-full mb-3 placeholder-gray-500 text-gray-900 ${
                        confirmacionClaveError !== '' &&
                        confirmacionClaveError !== 'valid' &&
                        'bg-red-200'
                      } ${mostrarClave || confirmacionClave.length === 0 ? '' : 'password-input'}`}
                      value={confirmacionClave}
                      autoComplete='none'
                      placeholder='(*) Confirmar Contraseña'
                      onChange={e => {
                        setconfirmacionClave(e.target.value);
                        if (e.target.value !== '') {
                          if (e.target.value === clave) {
                            setconfirmacionClaveError('valid');
                          } else {
                            setconfirmacionClaveError(
                              'Las contraseñas no coinciden.',
                            );
                          }
                        } else {
                          setconfirmacionClaveError(
                            'Confirmación de contraseña es requerida.',
                          );
                        }
                      }}
                    />
                  </div>
                  <MensajeValidacion error={confirmacionClaveError} />
                </div>
              </div>
            )}
            <MensajeError error={errorGeneral} />
            {errorResponse && errorResponse.length > 0 && (
              <div className='rounded-lg bg-red-100 p-6 my-10 w-2/4 flex flex-col items-center w-full'>
                <div className='flex items-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 text-red-600 mr-3'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                  <p className='text-red-600 font-bold'>
                    ¡Ups! Algo salió mal.
                  </p>
                </div>
                <div className='mt-4 text-left text-red-600'>
                  <ul className='list-disc pl-6'>
                    {errorResponse.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div>
              {!procesando && !mostrarMensajeExitoso && (
                <button
                  type='submit'
                  className='group relative w-full justify-center flex text-white font-medium items-center bg-[#FF6652] p-4 rounded-[60px]'>
                  Cambiar contraseña
                  <img className='ml-2 w-[55px]' src={IcoArrow} />
                </button>
              )}
              <div
                className={`w-full rounded-[60px] bg-white py-5 flex items-center pointer-events-none justify-center ${
                  procesando ? 'fade-in' : 'fade-out'
                }`}>
                <p
                  className={
                    'transition-all duration-500 ease-in-out transform animate-bounce'
                  }>
                  Cambiando contraseña...
                </p>
              </div>
            </div>
          </form>
        )}
        {mostrarMensajeExitoso && (
          <>
            <div className='rounded-lg bg-green-100 p-6 my-10 w-2/4 flex flex-col items-center w-full'>
              <div className='flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-green-600 mr-3'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='text-black font-semibold'>
                  Tu contraseña ha sido modificada correctamente.
                </p>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <a
                href='/login'
                className='group relative w-full justify-center flex text-white font-medium items-center bg-[#FF6652] p-4 rounded-[60px]'>
                Iniciar Sesión
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmarClaveForm;
