import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import './HabilidadesSuggestion.css';
import {toast} from 'react-toastify';
import * as Sentry from '@sentry/react';
import {HabilidadesAPI} from '../../../services/habilidades';
import {Checkbox} from '@mui/material';

const HabilidadSuggest = ({onValueChange}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [experiencia, setExperiencia] = useState('');
  const [value, setValue] = useState('');
  const [esRequerida, setEsRequerida] = useState(false);

  const notifyError = message => toast.error(message);

  const onChange = (event, {newValue}) => {
    setValue(capitalizeWords(newValue));
  };

  const onSuggestionsFetchRequested = ({value}) => {
    if (value.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      HabilidadesAPI.sugerencia(value)
        .then(response => {
          setSuggestions(response);
        })
        .catch(error => {
          Sentry.captureException(error);
          notifyError('Error al obtener sugerencias de habilidades.');
        });
    } catch (error) {
      Sentry.captureException(error);
      notifyError('Error al obtener sugerencias de habilidades.');
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Ingresa una habilidad ...',
    value,
    onChange: onChange,
    onKeyDown: event => {
      if (
        (event.key === 'Tab' || event.key === 'Enter') &&
        suggestions.length > 0
      ) {
        event.preventDefault();
        setValue(suggestions[0].nombreHabilidad);
      }
    },
  };

  const themeProps = {
    input: 'my-2 rounded-[10px] p-3 w-[300px]',
    suggestionsContainer: 'rounded-lg bg-white -mt-1 z-10',
    suggestion: 'suggestion',
    suggestionHighlighted: 'bg-gray-300',
  };

  function capitalizeWords(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach it how to do it.
  const getSuggestionValue = suggestion => suggestion.nombreHabilidad;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>{suggestion.nombreHabilidad}</div>
  );

  return (
    <>
      <div className='flex flex-col'>
        <div className='flex flex-row'>
          <div className='flex w-auto flex-col mt-2'>
            <label htmlFor='perfil'>Habilidades:</label>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              theme={themeProps}
            />
          </div>
          <div className='flex w-full flex-col mt-2 ml-5'>
            <label htmlFor='experiencia'>Experiencia:</label>
            <select
              className='my-2 rounded-[10px] p-3 w-full'
              id='experiencia'
              value={experiencia}
              onChange={e => setExperiencia(e.target.value)}>
              <option value=''>Selecciona una opción</option>
              <option value='Principiante'>
                Principiante: 0-6 meses de experiencia
              </option>
              <option value='Intermedio'>
                Intermedio: 6 meses - 3 años de experiencia
              </option>
              <option value='Experto'>Experto: 3+ años de experiencia</option>
            </select>
          </div>
          <div className='flex flex-col w-[300px] items-center justify-center'>
            <label htmlFor='requerido'>¿Requerida?</label>
            <Checkbox
              id='requerido'
              checked={esRequerida}
              onChange={e => setEsRequerida(e.target.checked)}
              inputProps={{'aria-label': 'controlled'}}
            />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='flex w-full items-center mt-2 px-5'>
            <button
              type='button'
              className='bg-gray-900 text-white rounded-[10px] w-full p-2 mt-2'
              onClick={() => {
                if (value === '' || experiencia === '') {
                  notifyError(
                    'Debe seleccionar una habilidad y una experiencia.',
                  );
                  return;
                }
                onValueChange(value, experiencia, esRequerida);
                setValue('');
                setExperiencia('');
                setEsRequerida(false);
              }}>
              Agregar habilidad
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HabilidadSuggest;
