import React from 'react';

const Layout = ({titulo, textoAyuda, children}) => {
  return (
    <div className='w-full'>
      <div className='mb-10 rounded-[60px]'>
        <h2 className='font-semibold'>{titulo}</h2>
        <p className='mt-1'>{textoAyuda}</p>
      </div>
      {children}
    </div>
  );
};

export default Layout;
