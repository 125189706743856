import React from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      window.location.href = '/error';
    }
  }

  render() {
    if (this.state.hasError) {
      return null; // You can render any fallback UI
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
