import React from 'react';
import FormValidacion from '../validacion/FormValidacion';

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  overrideStyle,
  validacion,
}) => {
  return (
    <>
      <label htmlFor={label}>{label}:</label>
      <input
        className={`my-2 rounded-[60px] p-3 w-full ${overrideStyle} ${
          validacion != '' && validacion != 'valid' && 'bg-red-200'
        }`}
        type={type}
        id={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      <FormValidacion error={validacion} />
    </>
  );
};

export default Input;
