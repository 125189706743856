import React, {useState} from 'react';
import Input from '../elements/input';
import IcoArrow from '../../assets/ico-arrow.png';
import ListaContactos from './ListaContactos';
import Modal from '../global/Modal';
import InputMask from 'react-input-mask';
import FormValidacion from '../validacion/FormValidacion';
import {useEffect} from 'react';
import {ContactosAPI} from '../../services/contactos';
import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {toast} from 'react-toastify';
import ConfirmDialog from '../global/ConfirmDialog';
import * as Sentry from '@sentry/react';

const FormularioContactos = () => {
  const [contactId, setContactId] = useState(0);
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = useState('');
  const [telefono, setTelefono] = useState('');
  const [telefonoError, setTelefonoError] = useState('');
  const [visualizarForm, setVisualizarForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [cargandoData, setCargandoData] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const notifyError = message => toast.error(message);
  const notifySuccess = message => toast.success(message);

  const handleOnDelete = contact => {
    setDeleteData(contact);
    setIsDialogOpen(true);
  };

  const forceFetchContacts = async () => {
    setCargandoData(true);
    ContactosAPI.obtener(sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID))
      .then(response => {
        setContacts(response);
      })
      .catch(error => {
        Sentry.captureException(error);
        notifyError('Error al obtener contactos.');
      })
      .finally(() => {
        setCargandoData(false);
      });
  };

  const handleDelete = () => {
    if (deleteData?.idEmpresaContacto) {
      setIsDeleting(true);
      ContactosAPI.inactivar(deleteData.idEmpresaContacto)
        .then(() => {
          forceFetchContacts();
          setIsDialogOpen(false);
          setDeleteData({});
          notifySuccess('Contacto eliminado correctamente.');
        })
        .catch(error => {
          notifyError(
            'Ocurrió un error al eliminar el contacto. Intente de nuevo.',
          );
          Sentry.captureException(error);
        })
        .finally(() => {
          setIsDeleting(false);
        });
    } else {
      notifyError('No se ha seleccionado un contacto para eliminar.');
    }
  };

  const EditarContacto = () => {
    const request = {
      idEmpresaContacto: contactId,
      idEmpresa: sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
      nombre: nombre,
      telefono: telefono,
      activo: 0,
    };

    ContactosAPI.editar(request)
      .then(() => {
        forceFetchContacts();
        setLoading(false);
        setVisualizarForm(false);
        setNombre('');
        setNombreError('');
        setTelefonoError('');
        setTelefono('');
        setContactId(0);
      })
      .catch(error => {
        Sentry.captureException(error);
        setLoading(false);
      });
  };

  const CrearContacto = () => {
    const request = {
      idEmpresaContacto: contactId,
      idEmpresa: sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
      nombre: nombre,
      telefono: telefono,
      activo: 0,
    };

    ContactosAPI.crear(request)
      .then(() => {
        forceFetchContacts();
        setLoading(false);
        setVisualizarForm(false);
        setNombre('');
        setNombreError('');
        setTelefonoError('');
        setTelefono('');
        setContactId(0);
      })
      .catch(error => {
        Sentry.captureException(error);
        setLoading(false);
      });
  };

  const handleOnCloseDialog = () => {
    setIsDialogOpen(false);
    setDeleteData({});
  };

  const handleOnEdit = contact => {
    setNombre(contact.nombre);
    setTelefono(contact.telefono);
    setContactId(contact.idEmpresaContacto);
    setVisualizarForm(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let isFormValid = true;

    if (nombre === '') {
      setNombreError('El nombre de contacto es requerido.');
      isFormValid = false;
    } else {
      setNombreError('valid');
    }

    if (telefono === '') {
      setTelefonoError('El teléfono es requerido.');
      isFormValid = false;
    } else {
      setTelefonoError('valid');
    }

    if (telefono.length < 8) {
      setTelefonoError('Teléfono no válido.');
      isFormValid = false;
    } else {
      setTelefonoError('valid');
    }

    if (isFormValid) {
      setLoading(true);
      if (contactId === 0) {
        CrearContacto();
      } else {
        EditarContacto();
      }
    }
  };

  const handleOnClose = () => {
    setVisualizarForm(false);
    setNombre('');
    setNombreError('');
    setTelefonoError('');
    setTelefono('');
    setContactId(0);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      setCargandoData(true);
      try {
        const response = await ContactosAPI.obtener(
          sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
        );
        setContacts(response);
        setCargandoData(false);
      } catch (error) {
        Sentry.captureException(error);
        notifyError('Error al obtener contactos.');
        setCargandoData(false);
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className='flex flex-wrap md:flex-nowrap'>
      <div className='w-full p-10 mt-5 rounded-[20px]'>
        <div className='w-full flex justify-end mb-2'>
          <button
            type='button'
            onClick={() => setVisualizarForm(true)}
            className='flex text-white font-medium items-center bg-black p-4 rounded-[20px] mb-4'>
            Nuevo Contacto
            <img className='ml-2 w-[55px]' src={IcoArrow} />
          </button>
        </div>
        <ListaContactos
          isLoading={cargandoData}
          contacts={contacts}
          onDelete={handleOnDelete}
          onEdit={handleOnEdit}
        />
      </div>
      <Modal isOpen={visualizarForm} onClose={handleOnClose}>
        <div className='w-[450px] p-5'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <Input
                label='Nombre del Contacto'
                type='text'
                placeholder='e.g. Juan Pérez'
                value={nombre}
                validacion={nombreError}
                onChange={e => {
                  if (e.target.value !== '') {
                    setNombreError('valid');
                  } else {
                    setNombreError('El nombre de contacto es requerido.');
                  }
                  setNombre(e.target.value);
                }}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='telefono'>Teléfono:</label>
              <InputMask
                className={`my-2 rounded-[60px] p-3 w-full ${
                  telefonoError !== '' &&
                  telefonoError !== 'valid' &&
                  'bg-red-200'
                }`}
                placeholder='(*) Teléfono'
                mask='9999-9999'
                value={telefono.replace('-', '')}
                onChange={e => {
                  let value = e.target.value
                    .replace(/-/g, '')
                    .replace(/_/g, '');
                  if (value !== '') {
                    if (value.length < 8) {
                      setTelefonoError('Teléfono no válido.');
                    } else {
                      setTelefonoError('valid');
                    }
                  } else {
                    setTelefonoError('El teléfono es requerido.');
                  }
                  setTelefono(value);
                }}
              />
              <FormValidacion error={telefonoError} />
            </div>

            <div className='w-full flex justify-end py-6'>
              <button
                type='submit'
                disabled={loading}
                className='flex text-white font-medium items-center bg-black p-4 rounded-[60px]'>
                {loading ? (
                  <span className='animate-pulse'>Creando...</span>
                ) : (
                  <>
                    {contactId === 0 ? 'Agregar Contacto' : 'Editar Contacto'}
                    <img className='ml-2 w-[55px]' src={IcoArrow} />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmDialog
        open={isDialogOpen}
        title='Eliminar Contacto'
        description='¿Está seguro que desea eliminar este contacto? Esta acción no se puede deshacer.'
        onClose={handleOnCloseDialog}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default FormularioContactos;
