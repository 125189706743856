import {api} from '../config';

export const LoginAPI = {
  validar: async function (request) {
    const response = await api.request({
      url: '/api/v1/Auth/Login',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
      },
    });

    return response?.data;
  },
  generarToken: async function (request) {
    const response = await api.request({
      url: '/api/v1/Auth/generate-token',
      method: 'PUT',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
      },
    });

    return response?.data;
  },
  cambiarClave: async function (request) {
    const response = await api.request({
      url: '/api/v1/Auth/password',
      method: 'PUT',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
      },
    });

    return response?.data;
  },
};
