import React, {useState} from 'react';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import {DataGrid, esES} from '@mui/x-data-grid';
import {IoIosRemoveCircleOutline, IoIosEye} from 'react-icons/io';
import {Button} from '@mui/material';
import {useEffect} from 'react';
import {PlazaAPI} from '../services/plaza';
import {toast} from 'react-toastify';
import LoadingIndicator from '../components/global/LoadingIndicator';
import {Link} from 'react-router-dom';

const Plazas = () => {
  const [plazasResumen, setPlazasResumen] = useState([]);
  const [estaCargando, setEstaCargando] = useState(true);

  const notifyError = message => toast.error(message);
  const notifyWarning = message => toast.warning(message);
  const notifySuccess = message => toast.success(message);

  useEffect(() => {
    const obtenerPlazasResumen = () => {
      PlazaAPI.obtenerResumen()
        .then(response => {
          setPlazasResumen(response);
        })
        .catch(error => {
          notifyError('Ocurrió un error al obtener las plazas.');
        })
        .finally(() => {
          setEstaCargando(false);
        });
    };

    obtenerPlazasResumen();

    return () => {};
  }, []);

  const columns = [
    {
      field: 'titulo',
      headerName: 'Título',
      width: 150,
      renderCell: params => {
        return (
          <Link
            to={`/detalle/${params.row.idPlaza}`}
            className='font-bold underline break-words whitespace-normal'>
            {params.row.titulo}
          </Link>
        );
      },
    },
    {
      field: 'perfil',
      headerName: 'Perfil',
      width: 150,
      renderCell: params => {
        return (
          <span className='break-words whitespace-normal'>
            {params.row.titulo}
          </span>
        );
      },
    },
    {field: 'cantidadPlazas', headerName: 'Vacantes', width: 130},
    {
      headerName: 'Aplicantes',
      width: 140,
      renderCell: params => {
        return (
          <Link
            to={`/detalle/${params.row.idPlaza}`}
            className='font-bold underline break-words whitespace-normal'>
            {params.row.titulo}
          </Link>
        );
      },
    },
    {
      headerName: 'Contratados',
      width: 150,
      field: 'contratados',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      width: 130,
      renderCell: params => {
        return (
          <div className='flex flex-col justify-center items-center w-full'>
            <Link
              to={`/detalle/${params.row.idPlaza}`}
              className='font-bold underline break-words whitespace-normal'>
              <IoIosEye className='h-5 w-5 text-blue-500' />
            </Link>
          </div>
        );
      },
    },
  ];

  function getRowId(row) {
    return row.idPlaza;
  }

  return (
    <MainWrapper>
      <div className='flex flex-row w-full'>
        <h1 className='text-3xl font-medium mr-14'>
          Lista de <span className='font-semibold'>plazas</span> Activas
        </h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      {/* Other dashboard content */}
      <div className='mt-10 max-w-[850px]'>
        {estaCargando && (
          <div className='flex justify-center items-center h-64'>
            <LoadingIndicator />
          </div>
        )}
        {!estaCargando && (
          <DataGrid
            autoHeight
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            getRowId={getRowId}
            rows={plazasResumen}
            columns={columns}
            sx={{
              '& .font-bold': {
                fontWeight: '700', // Apply bold styling similar to Tailwind's font-bold
              },
              '--DataGrid-overlayHeight': '300px',
              borderRadius: '15px',
              boxShadow: 1,
            }}
          />
        )}
      </div>
    </MainWrapper>
  );
};

export default Plazas;
