import React from 'react';
import logo from '../../assets/registro/logo-blanco.png';

const Footer = () => {
  return (
    <footer className='bg-gray-800 text-white py-6 h-min-[125px]'>
      <div className=' mx-20 flex justify-between items-center'>
        <div>
          <img src={logo} alt='Logo' className=' h-10 w-auto' />
        </div>
        <div className='flex space-x-4'>
          <a href='/privacy' className='hover:underline'>
            Política de Privacidad
          </a>
          <a href='https://wa.me/50670606156' className='hover:underline'>
            (506) 7060-6156
          </a>
          <a href='mailto:info@lumywork.com' className='hover:underline'>
            info@lumywork.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
