import React, {useState, useRef} from 'react';
import IcoArrow from '../../assets/ico-arrow.png';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ScheduleSlotComponent from './ScheduleSlotComponent';
import SelectorUbicacion from './SelectorUbicacion';
import Contactos from './components/Contactos';
import HabilidadSuggest from './components/HabilidadesSuggestion';
import HelpText from '../common/HelpText';
import ListaHabilidades from './components/ListaHabilidades';
import {toast} from 'react-toastify';
import PerfilSuggestion from './components/PerfilSuggestion';
import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {PlazaAPI} from '../../services/plaza';
import {CircularProgress} from '@mui/material';
import Summary from './components/Summary';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import './ReactQuill.css';
import {IoCalendarNumberOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import InputMask from 'react-input-mask';
import {NumericFormat} from 'react-number-format';

const FormularioTrabajo = () => {
  const navigate = useNavigate();
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [contacto, setContacto] = useState('');
  const [contactoDescripcion, setContactoDescripcion] = useState('');
  const [perfil, setPerfil] = useState('');
  const [pasoActual, setPasoActual] = useState('1');
  const [salario, setSalario] = useState('0');
  const [descripcionCorta, setDescripcionCorta] = useState('');
  const [descripcionCortaError, setDescripcionCortaError] = useState('');
  const [tipoSalario, setTipoSalario] = useState('');
  const [habilidades, setHabilidades] = useState([]);
  const [tipoPlaza, setTipoPlaza] = useState('');
  const [turnos, setTurnos] = useState([]);
  const [cantidadPlazas, setCantidadPlazas] = useState(1);
  const [guardando, setGuardando] = useState(false);
  const [informacionPlaza, setInformacionPlaza] = useState({});
  const [ubicacionPlaza, setUbicacionPlaza] = useState({});
  const [fechaInicio, setFechaInicio] = useState(new Date());

  //Refs
  const salarioInput = useRef(null);

  //Constantes
  const descripcionCortaMaxLength = 200;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{list: 'ordered'}, {list: 'bullet'}],
    ],
  };

  const notifyError = message => toast.error(message);
  const notifyWarning = message => toast.warning(message);
  const notifySuccess = message => toast.success(message);

  const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];

  const handlePaso1Submit = e => {
    e.preventDefault();
    if (
      !titulo ||
      !descripcion ||
      !contacto ||
      !descripcionCorta ||
      !fechaInicio ||
      !descripcion
    ) {
      notifyError('Verifique que todos los campos estén completos.');
    } else {
      setPasoActual('2');
    }

    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const handlePaso2Submit = e => {
    e.preventDefault();
    if (!perfil || !tipoSalario || !salario || habilidades.length === 0) {
      notifyError('Todos los campos son requeridos');
    } else {
      if (tipoPlaza === 'Temporal') {
        setPasoActual('3');
      } else {
        setPasoActual('4');
      }
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };

  const handleDescripcionCortaChange = event => {
    if (event.target.value.length <= descripcionCortaMaxLength) {
      setDescripcionCorta(event.target.value);
    }
  };

  const handleOnContactChange = (value, text) => {
    setContacto(value);
    setContactoDescripcion(text);
  };

  const onTipoDeSalarioChange = e => {
    setTipoSalario(e.target.value);
    setTimeout(() => {
      salarioInput.current.focus();
    }, 200);
  };

  function capitalizeWords(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const handleHabilidadesSuggestions = (value, experiencia, esRequerida) => {
    const exists = habilidades.some(
      h => h.habilidad.toLowerCase() === value.toLowerCase(),
    );

    if (!exists) {
      const id = habilidades.length + 1;
      setHabilidades([
        ...habilidades,
        {
          id: id,
          habilidad: capitalizeWords(value),
          experiencia: experiencia,
          esRequerida: esRequerida ? 'Si' : 'No',
        },
      ]);
    } else {
      notifyWarning('La habilidad ya fue agregada.');
    }
  };

  const handleOnDeleteHabilidad = row => {
    setHabilidades(habilidades.filter(item => item.id !== row.id));
  };

  const handleOnPerfilChange = value => {
    setPerfil(capitalizeWords(value));
  };

  const handleUbicacionOnSubmit = localizacion => {
    setInformacionPlaza({
      titulo,
      tipoPlaza,
      descripcionCorta,
      descripcion,
      contactoDescripcion,
      perfil,
      cantidadPlazas,
      tipoSalario,
      salario,
      habilidades,
      turnos,
      localizacion,
    });
    setUbicacionPlaza(localizacion);
    setPasoActual('5');
  };

  const handleOnScheduleSubmit = listaTurnos => {
    setTurnos(listaTurnos);
    setPasoActual('4');
  };

  const handleOnSubmit = () => {
    setGuardando(true);
    const plazaHabilidades = habilidades.map(h => {
      return {
        habilidad: h.habilidad,
        requerido: h.esRequerida === 'Si' ? true : false,
        experiencia: h.experiencia,
      };
    });

    const plazaTurnos = turnos.map(t => {
      const [dia, mes, anno] = t.date.split('/').map(Number);
      const [horaInicio, minutosInicio] = t.startTime.split(':').map(Number);
      const [horaFin, minutosFin] = t.endTime.split(':').map(Number);

      return {
        dia: dia,
        mes: mes,
        anno: anno,
        horaInicio: horaInicio,
        minutosInicio: minutosInicio,
        horaFin: horaFin,
        minutosFin: minutosFin,
      };
    });

    const salarioNumerico = Number(salario.replace(',', '').replace('.', ''));
    const body = {
      idEmpresa: sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
      titulo: titulo,
      descripcionCorta: descripcionCorta,
      descripcion: descripcion,
      esTemporal: tipoPlaza === 'Temporal' ? true : false,
      lat: ubicacionPlaza.lat,
      lon: ubicacionPlaza.lng,
      provincia: ubicacionPlaza.provinciaNombre,
      canton: ubicacionPlaza.cantonNombre,
      distrito: ubicacionPlaza.distritoNombre,
      direccionExacta: ubicacionPlaza.direccionExacta,
      salario: salarioNumerico,
      perfil: perfil,
      tipoSalario: tipoSalario,
      tipoPlaza: tipoPlaza,
      plazaHabilidades: plazaHabilidades,
      cantidadPlazas: cantidadPlazas,
      turnos: plazaTurnos,
      idContacto: Number(contacto),
      fechaInicio: fechaInicio.toISOString().split('T')[0],
    };

    PlazaAPI.agregar(body)
      .then(response => {
        setGuardando(false);
        notifySuccess('Plaza agregada correctamente.');
        navigate('/plazas');
      })
      .catch(error => {
        setGuardando(false);
        console.log(error);
        notifyError('Ocurrió un error al agregar plaza.');
      });
  };

  return (
    <div className='flex flex-col max-w-[850px] min-w-[800px] mt-10 bg-[#dce5e7] p-10 rounded-[20px]'>
      {pasoActual === '1' && (
        <form className='w-full' onSubmit={handlePaso1Submit}>
          <div className='mb-10 rounded-[20px]'>
            <h2 className='font-semibold'>Información Básica</h2>
            <p className='mt-1'>Proporciona información básica de la plaza.</p>
          </div>
          <div className='flex flex-col mb-2'>
            <div className='flex flex-row'>
              <label htmlFor='tipoPlaza'>Tipo de plaza (*):</label>
            </div>

            <select
              id='tipoPlaza'
              value={tipoPlaza}
              className='my-2 rounded-[10px] p-3 w-full'
              onChange={e => setTipoPlaza(e.target.value)}>
              <option value=''>Seleccione una opción</option>
              <option value='Indefinido'>Indefinido</option>
              <option value='Temporal'>Temporal</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <label htmlFor='titulo'>Título de la plaza (*):</label>
            <input
              className='my-2 rounded-[10px] p-3 w-full'
              type='text'
              id='titulo'
              value={titulo}
              placeholder='Ej. Asistente de Eventos'
              onChange={e => setTitulo(capitalizeWords(e.target.value))}
            />
          </div>
          <div className='flex flex-col mt-2'>
            <div className='inline'>
              <label htmlFor='descripcion'>Descripción corta (*):</label>
              <small className='ml-2'>
                (Es importante una descripción breve de lo que trata la plaza)
              </small>
            </div>

            <textarea
              value={descripcionCorta}
              rows={3}
              className={`w-full rounded-[10px] mt-2 leading-none py-3 px-3 mb-12 resize-none ${
                descripcionCortaError !== '' &&
                descripcionCortaError !== 'valid' &&
                'bg-red-200'
              }`}
              placeholder='Esta descripción será una prevista en la lista de trabajos.'
              onChange={e => {
                if (e.target.value !== '') {
                  setDescripcionCortaError('valid');
                } else {
                  setDescripcionCortaError(
                    'Se requiere una descripción corta del trabajo.',
                  );
                }

                handleDescripcionCortaChange(e);
              }}
            />
            <p
              className='text-[14px] -mt-[80px] z-10 text-right mr-8 mb-8 w-full pr-4'
              style={{
                color:
                  descripcionCortaMaxLength - descripcionCorta.length >= 50
                    ? 'black'
                    : 'red',
              }}>
              {descripcionCorta.length}/
              {descripcionCortaMaxLength - descripcionCorta.length}
            </p>
          </div>
          <div className='flex flex-col mt-2'>
            <label htmlFor='descripcion'>Descripción Detallada (*):</label>
            <ReactQuill
              placeholder=''
              value={descripcion}
              onChange={value => setDescripcion(value)}
              modules={modules}
              formats={formats}
              className='my-2 rounded-[10px] w-full text-base'
            />
          </div>
          <div className='flex flex-col mt-2'>
            <div className='mb-2'>
              <label className='mb-2' htmlFor='fechaInicio'>
                Fecha límite (*):
              </label>
              <small className='ml-2'>
                (Fecha máxima en que se van a recibir aplicaciones)
              </small>
            </div>

            <DatePicker
              showIcon
              locale='es'
              selected={fechaInicio}
              onChange={date => setFechaInicio(date)}
              dateFormat='dd/MM/yyyy'
              minDate={new Date()}
              icon={<IoCalendarNumberOutline />}
              className='rounded-[10px] py-3 px-4 border border-gray-300 focus:outline-none focus:border-[#FF6652]'
            />
          </div>
          <div className='flex flex-col mt-2'>
            <Contactos value={contacto} onChange={handleOnContactChange} />
          </div>
          <div className='w-full flex justify-end py-6'>
            <button
              type='submit'
              className='flex text-white font-medium items-center bg-gray-900 p-2 px-3 rounded-[10px]'>
              Agregar información adicional
              <img className='ml-2 w-[55px]' src={IcoArrow} />
            </button>
          </div>
        </form>
      )}
      {pasoActual === '2' && (
        <form className='w-full' onSubmit={handlePaso2Submit}>
          <div className='flex flex-1 flex-row w-full'>
            <div className='flex w-full flex-col mt-2'>
              <label htmlFor='perfil'>Perfil (*):</label>
              <HelpText text='¿Que perfil andas buscando para esta plaza?' />
              <PerfilSuggestion
                perfil={perfil}
                onPerfilChange={handleOnPerfilChange}
              />
            </div>
          </div>
          <div className='flex flex-1 flex-row w-2/3 mt-4'>
            <div className='inline-block w-full'>
              <label className='block mb-2'>Número de Plazas (*):</label>
              <HelpText text='¿Cuántos colaboradores requieres para esta plaza?' />
              <input
                type='number'
                value={cantidadPlazas}
                onChange={e => setCantidadPlazas(e.target.value)}
                min={1}
                onPaste={e => e.preventDefault()}
                className='rounded-[10px] w-[100px] py-2 px-4 border border-gray-300 focus:outline-none focus:border-[#FF6652]'
              />
            </div>
          </div>
          <div className='flex flex-1 flex-row w-2/3 mt-4'>
            <div className='flex flex-col mt-2 mr-4'>
              <label htmlFor='tipoSalario'>Tipo de Salario:</label>
              <select
                id='tipoSalario'
                value={tipoSalario}
                className='my-2 rounded-[10px] p-3 w-full'
                onChange={e => onTipoDeSalarioChange(e)}>
                <option value=''>Selecciona una opción</option>
                <option value='por hora'>Por hora</option>
                <option value='por día'>Por día</option>
                <option value='por mes'>Por mes</option>
              </select>
            </div>
            <div className='flex flex-col mt-2'>
              <label htmlFor='salario'>
                {tipoSalario === ''
                  ? 'Seleccione un tipo de salario'
                  : `Salario ${tipoSalario} (₡) :`}
              </label>
              <NumericFormat
                disabled={tipoSalario === ''}
                className='my-2 rounded-[10px] p-3 w-2/3'
                onPaste={e => e.preventDefault()}
                id='salario'
                ref={salarioInput}
                value={salario}
                decimalSeparator='.'
                thousandSeparator=','
                allowNegative={false}
                allowLeadingZeros={false}
                onChange={e => setSalario(e.target.value)}
              />
            </div>
          </div>

          <div className='flex flex-1 flex-row w-full mt-4 bg-[#f4f4f4] p-5 rounded-[10px]'>
            <HabilidadSuggest onValueChange={handleHabilidadesSuggestions} />
          </div>
          <div className='flex flex-1 flex-row w-full mt-4'>
            <ListaHabilidades
              habilidades={habilidades}
              onDelete={handleOnDeleteHabilidad}
            />
          </div>
          <div className='w-full flex justify-end py-6'>
            <button
              className='flex font-medium items-center p-4'
              onClick={() => {
                if (tipoPlaza === 'Temporal') {
                  setPasoActual('1');
                } else {
                  setPasoActual('1');
                }
              }}>
              Atrás
            </button>
            <button
              type='submit'
              className='flex text-white font-medium items-center bg-gray-900 p-2 px-3 rounded-[10px]'>
              {tipoPlaza === 'Temporal'
                ? 'Agregar turnos'
                : 'Agregar ubicación'}
              <img className='ml-2 w-[55px]' src={IcoArrow} />
            </button>
          </div>
        </form>
      )}
      {pasoActual === '3' && (
        <div className='w-full'>
          <div className='flex flex-col mt-2'>
            <ScheduleSlotComponent
              fechaInicio={fechaInicio}
              handleOnSubmit={handleOnScheduleSubmit}
              setPasoActual={setPasoActual}
            />
          </div>
        </div>
      )}
      {pasoActual === '4' && (
        <div className='w-full'>
          <div className='flex flex-col mt-2'>
            <SelectorUbicacion
              onSubmit={handleUbicacionOnSubmit}
              onBack={() => setPasoActual('2')}
            />
          </div>
        </div>
      )}
      {pasoActual === '5' && (
        <>
          {guardando ? (
            <div className='w-full'>
              <div className='flex flex-col mt-2'>
                <div className='flex flex-col justify-center items-center'>
                  <CircularProgress sx={{color: '#FF6652'}} />
                  <span className='mt-5'>Guardando...</span>
                </div>
              </div>
            </div>
          ) : (
            <Summary
              informacionPlaza={informacionPlaza}
              onSubmit={handleOnSubmit}
              onBack={() => setPasoActual('4')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FormularioTrabajo;
