import React from 'react';
import LoginForm from '../components/auth/LoginForm';
import LogoLumy from '../assets/registro/logo-lumy-salmon.png';

const Login = () => {
  return (
    <>
      <div className='min-h-screen bg-[#DADADA] h-auto'>
        <div className='grid grid-cols-3 min-h-screen md:grid-cols-3'>
          <div className='flex justify-start items-center sm:hidden'>
            <img
              src={LogoLumy}
              alt='Logo Lumy'
              className='w-[500px] ml-20 mt-20 md:w-[250px] md:ml-2 lg:w-[300px] lg:ml-0'
            />
          </div>
          <div className='col-span-2 sm:col-span-3 md:col-span-2'>
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
