import {createSlice} from '@reduxjs/toolkit';
import LOCAL_STORAGE_KEYS from '../constants/localStorageKeys';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      if (action.payload) {
        sessionStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_ID,
          action.payload?.idLogin,
        );
        sessionStorage.setItem(
          LOCAL_STORAGE_KEYS.EMPRESA_ID,
          action.payload?.empresa.idEmpresa,
        );

        if (action.payload?.empresa) {
          sessionStorage.setItem(
            LOCAL_STORAGE_KEYS.PERFIL,
            JSON.stringify(action.payload?.empresa),
          );
        }
      }
    },
    setToken(state, action) {
      state.token = action.payload;
      sessionStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, action.payload);
    },
    clearUser(state) {
      state.user = null;
      state.token = null;
    },
  },
});

export const {setUser, setToken, clearUser} = userSlice.actions;

export default userSlice.reducer;
