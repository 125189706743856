import React, {useEffect, useState} from 'react';
import BaseWidget from './BaseWidget';
import {PlazaAPI} from '../../services/plaza';

const WidgetCantidadPlazas = () => {
  const [contador, setContador] = useState('0');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlazas = async () => {
      try {
        const response = await PlazaAPI.obtenerPlazasActivas();
        setContador(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPlazas();
  }, []);

  if (loading) {
    return <BaseWidget titulo='Plazas' contador='...' />;
  }

  if (error) {
    return <BaseWidget titulo='Plazas' contador='Error' />;
  }

  return (
    <>
      <BaseWidget titulo='Plazas' contador={contador} />
    </>
  );
};

export default WidgetCantidadPlazas;
