import {createContext, ReactNode, useState} from 'react';

const initialValue = {
  authenticated: false,
  setAuthenticated: () => {},
};

const AuthContext = createContext(initialValue);

const AuthProvider = ({children}) => {
  //Initializing an auth state with false value (unauthenticated)
  const [authenticated, setAuthenticated] = useState(
    initialValue.authenticated,
  );

  return (
    <AuthContext.Provider value={{authenticated, setAuthenticated}}>
      {children}
    </AuthContext.Provider>
  );
};

export {AuthContext, AuthProvider};
