import React, {useState, useEffect} from 'react';
import {Routes, Route, useParams} from 'react-router-dom';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import LoadingIndicator from '../components/global/LoadingIndicator';
import {DataGrid, esES} from '@mui/x-data-grid';
import {toast} from 'react-toastify';
import {AplicantesAPI} from '../services/aplicantes';
import {Avatar, Button} from '@mui/material';
import {IoIosEye, IoIosHome, IoIosRemoveCircleOutline} from 'react-icons/io';
import Modal from '../components/global/Modal';

const DetalleAplicantes = () => {
  let {plazaId} = useParams();
  const [aplicantesResumen, setAplicantesResumen] = useState([]);
  const [estaCargando, setEstaCargando] = useState(true);
  const [visualizarForm, setVisualizarForm] = useState(false);
  const [perfil, setPerfil] = useState({});

  const notifyError = message => toast.error(message);
  const notifyWarning = message => toast.warning(message);
  const notifySuccess = message => toast.success(message);

  const obtenerAvatar = nombre => {
    if (!nombre) {
      return;
    }

    const primerasLetras = nombre.substring(0, 1).toUpperCase();
    return <Avatar className='mr-3'>{primerasLetras}</Avatar>;
  };

  const onModalClosed = () => {
    setVisualizarForm(false);
    setPerfil({});
  };

  const abrirDetalle = idUsuario => {
    var data = aplicantesResumen.find(
      x => x.idusuarioColaborador === idUsuario,
    );
    if (data) {
      setPerfil(data);
      setVisualizarForm(true);
    } else {
      notifyError('Ocurrió un error al obtener los datos del aplicante');
    }
  };

  const columns = [
    {
      field: 'perfil',
      headerName: 'Perfil',
      width: 400,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <div className='flex flex-row w-[500px]'>
            <div className='flex justify-center items-center'>
              {params.row.urlFoto !== null && (
                <img
                  className='w-[42px] h-[42px] mx-2 rounded-full'
                  src={`https://lumy-app.s3.amazonaws.com/${params.row.urlFoto}`}></img>
              )}
              {params.row.urlFoto === null && obtenerAvatar(params.row.nombre)}
            </div>
            <div className='flex flex-col'>
              <span className='font-semibold'>
                {params.row.nombre} {params.row.apellidos}
              </span>
              <span>{params.row.perfil}</span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      headerAlign: 'center',
      renderCell: params => {
        return params.row.contratado === false ? (
          <div className='bg-blue-500 text-white p-2 rounded-lg'>Aplicante</div>
        ) : (
          <div className='bg-green-500 text-white p-2 rounded-lg'>
            Contratado
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 400,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <strong>
            <Button
              variant='outlined'
              size='small'
              style={{marginLeft: 16}}
              onClick={() => abrirDetalle(params.row.idusuarioColaborador)}
              sx={{borderRadius: '10px'}}
              startIcon={<IoIosEye />}>
              Ver perfil
            </Button>
            {!params.row.contratado && (
              <Button
                variant='outlined'
                color='success'
                size='small'
                style={{marginLeft: 16}}
                href={`/detalle/${params.row.idusuarioColaborador}`}
                sx={{borderRadius: '10px'}}
                startIcon={<IoIosEye />}>
                Contratar
              </Button>
            )}
          </strong>
        );
      },
    },
  ];

  function getRowId(row) {
    return row.idusuarioColaborador;
  }

  useEffect(() => {
    setEstaCargando(true);
    AplicantesAPI.obtenerResumen(plazaId)
      .then(res => {
        setEstaCargando(false);
        setAplicantesResumen(res);
      })
      .catch(error => {
        setEstaCargando(false);
        notifyError('Ocurrió un error al obtener los aplicantes');
      });

    return () => {};
  }, []);

  return (
    <MainWrapper>
      <div className='flex flex-row w-full'>
        <h1 className='text-3xl font-medium mr-14'>
          Lista de <span className='font-semibold'>aplicantes</span>
        </h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      <div className='max-w-[90%] mt-10'>
        {estaCargando && (
          <div className='flex justify-center items-center h-64'>
            <LoadingIndicator />
          </div>
        )}
        {!estaCargando && (
          <DataGrid
            autoHeight
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            getRowId={getRowId}
            rows={aplicantesResumen}
            columns={columns}
            sx={{
              '&.MuiDataGrid-root': {
                fontFamily: 'urbanist',
              },
              '& .font-bold': {
                fontWeight: '700', // Apply bold styling similar to Tailwind's font-bold
              },
              '--DataGrid-overlayHeight': '300px',
              borderRadius: '15px',
              boxShadow: 1,
            }}
          />
        )}
      </div>
      <Modal isOpen={visualizarForm} onClose={onModalClosed}>
        <div className='w-[700px]'>
          <div>
            <div className='flex flex-row w-full'>
              <div className='flex justify-start mr-5'>
                {perfil.urlFoto !== null && (
                  <img
                    className='w-[150px] h-[150px] mx-2'
                    src={`https://lumy-app.s3.amazonaws.com/${perfil.urlFoto}`}></img>
                )}
                {perfil.urlFoto === null && obtenerAvatar(perfil.nombre)}
              </div>
              <div className='flex flex-col flex-1'>
                <div className='flex'>
                  <span className='font-bold text-xl'>
                    {perfil.nombre} {perfil.apellidos}
                  </span>
                </div>
                <div className='flex flex-row items-center mb-2'>
                  <span className='mt-2'> {perfil.perfil}</span>
                </div>
                <div className='flex flex-row items-center'>
                  <IoIosHome className='mt-1 mr-1' />
                  <span className='mt-2'>
                    {' '}
                    {perfil.provincia}, {perfil.canton}, {perfil.distrito}
                  </span>
                </div>
                <div className='flex mt-5'>
                  <span className='mt-2 font-bold'>Sobre {perfil.nombre}</span>
                </div>
                <div className='flex'>
                  <p className='mt-2'>{perfil.textoSobreMi}</p>
                </div>
                {perfil.habilidades && (
                  <div className='flex mt-5'>
                    <span className='mt-2 font-bold'>
                      Habilidades de {perfil.nombre}
                    </span>
                  </div>
                )}
                {perfil.habilidades && (
                  <ul className='flex flex-col mt-5 list-disc'>
                    {perfil.habilidades.map((habilidad, index) => (
                      <li key={index} className='my-1'>
                        {habilidad.habilidad}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className='flex flex-col'></div>
          </div>
        </div>
      </Modal>
    </MainWrapper>
  );
};

export default DetalleAplicantes;
