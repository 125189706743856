import React from 'react';
import LoadingIndicator from '../global/LoadingIndicator';
import {DataGrid, esES} from '@mui/x-data-grid';
import {Button} from '@mui/material';
import {AiOutlineEdit} from 'react-icons/ai';
import {IoIosRemoveCircleOutline} from 'react-icons/io';

const ListaContactos = ({contacts, onEdit, onDelete, isLoading}) => {
  const columns = [
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 150,
      headerClassName: 'font-bold',
    },
    {field: 'telefono', headerName: 'Teléfono', width: 150},
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 250,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <strong>
            <Button
              variant='outlined'
              color='primary'
              size='small'
              style={{marginLeft: 16}}
              onClick={() => onEdit(params.row)}
              startIcon={<AiOutlineEdit />}
              sx={{borderRadius: '10px'}}>
              Editar
            </Button>
            <Button
              variant='outlined'
              color='error'
              size='small'
              style={{marginLeft: 16}}
              onClick={() => onDelete(params.row)}
              sx={{borderRadius: '10px'}}
              startIcon={<IoIosRemoveCircleOutline />}>
              Eliminar
            </Button>
          </strong>
        );
      },
    },
  ];

  function getRowId(row) {
    return row.idEmpresaContacto;
  }

  return (
    <>
      {isLoading && (
        <div className='flex justify-center items-center h-64'>
          <LoadingIndicator />
        </div>
      )}
      {!isLoading &&
        (!contacts || contacts.length === 0 ? (
          <p className='text-center text-black'>
            No hay ningún contacto creado.
          </p>
        ) : (
          <div>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              getRowId={getRowId}
              rows={contacts}
              columns={columns}
              sx={{
                '& .font-bold': {
                  fontWeight: '700', // Apply bold styling similar to Tailwind's font-bold
                },
                borderRadius: '15px',
                boxShadow: 1,
              }}
            />
          </div>
        ))}
    </>
  );
};

export default ListaContactos;
