import React, {useState} from 'react';
import MensajeValidacion from '../validacion/MensajeValidacion';
import MensajeError from '../common/MensajeError';
import IcoArrow from '../../assets/ico-arrow.png';
import {LoginAPI} from '../../services/auth';
import * as Sentry from '@sentry/react';

import LogoBotonImg from '../../assets/registro/logo-boton.png';

const RecuperarClaveForm = () => {
  //states
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorGeneral, setErrorGeneral] = useState('');
  const [procesando, setProcesando] = useState(false);
  const [errorResponse, setErrorResponse] = useState([]);
  const [mostrarMensajeExitoso, setMostrarMensajeExitoso] = useState(false);

  const validateEmail = email => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const esValido = emailPattern.test(email);
    if (!esValido) {
      setEmailError('Debe ingresar un correo electrónico válido.');
    } else {
      setEmailError('valid');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setErrorGeneral('');
    setErrorResponse([]);

    validateEmail(email);

    if (emailError === 'valid') {
      setProcesando(true);

      var request = {
        email: email?.toLowerCase().trim(),
      };

      LoginAPI.generarToken(request)
        .then(response => {
          setProcesando(false);

          setMostrarMensajeExitoso(true);
        })
        .catch(error => {
          setProcesando(false);

          const mensajeError = [
            'Se ha producido un error al intentar comunicarse con el servidor. Por favor, inténtalo de nuevo.',
          ];
          setErrorResponse(mensajeError);
          Sentry.captureException(error);
        });
    } else {
      setErrorGeneral('Debe verificar los campos requeridos para continuar.');
    }
  };

  const handleEmailChange = e => {
    setEmailError('');
    const emailValue = e.target.value;
    setEmail(emailValue);
    validateEmail(emailValue);
  };

  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100'>
      <div className='max-w-md w-full space-y-8'>
        <div className='flex justify-center'>
          <img className='mb-10' src={LogoBotonImg} />
        </div>
        <div>
          <h2 className='text-4xl leading-[88%] text-center my-5 mt-10'>
            Recuperar tu <span className='font-semibold'>contraseña</span>
          </h2>
        </div>
        <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
          {!procesando && (
            <div className={!procesando ? 'fade-in' : 'fade-out'}>
              <p className='text-center mb-4'>
                Para recuperar tu contraseña, por favor, introduce a
                continuación tu correo electrónico.
              </p>
              {!mostrarMensajeExitoso && (
                <div>
                  <label htmlFor='email' className='sr-only'>
                    Correo Electrónico
                  </label>
                  <input
                    id='email'
                    value={email}
                    type='email'
                    autoComplete='email'
                    onChange={e => handleEmailChange(e)}
                    className={`rounded-[60px] py-3 px-3 w-full placeholder-gray-500 text-gray-900 mb-3 ${
                      emailError !== '' &&
                      emailError !== 'valid' &&
                      'bg-red-200'
                    }`}
                    placeholder='(*) Correo Electrónico'
                  />
                  <MensajeValidacion error={emailError} />
                </div>
              )}
            </div>
          )}
          <MensajeError error={errorGeneral} />
          {errorResponse && errorResponse.length > 0 && (
            <div className='rounded-lg bg-red-100 p-6 my-10 w-2/4 flex flex-col items-center w-full'>
              <div className='flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-red-600 mr-3'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
                <p className='text-red-600 font-bold'>¡Ups! Algo salió mal.</p>
              </div>
              <div className='mt-4 text-left text-red-600'>
                <ul className='list-disc pl-6'>
                  {errorResponse.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {mostrarMensajeExitoso && (
            <div className='rounded-lg bg-green-100 p-6 my-10 w-2/4 flex flex-col items-center w-full'>
              <div className='flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-green-600 mr-3'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <p className='text-black font-semibold'>
                  Te hemos enviado un correo electrónico con las instrucciones
                  para recuperar la contraseña.
                </p>
              </div>
            </div>
          )}
          <div>
            {!procesando && !mostrarMensajeExitoso && (
              <button
                type='submit'
                className='group relative w-full justify-center flex text-white font-medium items-center bg-[#FF6652] p-4 rounded-[60px]'>
                Recuperar Contraseña
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </button>
            )}
            <div
              className={`w-full rounded-[60px] bg-white py-5 flex items-center pointer-events-none justify-center ${
                procesando ? 'fade-in' : 'fade-out'
              }`}>
              <p
                className={
                  'transition-all duration-500 ease-in-out transform animate-bounce'
                }>
                Procesando...
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecuperarClaveForm;
