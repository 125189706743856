import axios from 'axios';
import * as Sentry from '@sentry/react';
import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';

export const api = axios.create({
  baseURL: 'https://api.lumywork.com',
  //baseURL: 'http://192.168.100.10:5258',
  //baseURL: 'http://192.168.10.73:5258',
});

const errorHandler = error => {
  if (error?.code === 'ERR_NETWORK') {
    Sentry.captureException(error);
    return Promise.reject(error);
  }

  const statusCode = error.response?.status;

  if (
    error.response &&
    error.response.status === 401 &&
    window.location.pathname !== '/login'
  ) {
    // Token might be expired, handle the redirect
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN); // Clear the token
    window.location = '/login'; // Redirect to login
  } else if (statusCode === 401 && window.location.pathname === '/login') {
    return Promise.reject(error);
  } else if (statusCode && statusCode !== 401) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
};

api.interceptors.response.use(undefined, error => {
  if (error.response && error.response.status === 500) {
    window.location.href = '/error';
  }

  return errorHandler(error);
});
