import React from 'react';
import Layout from './Layout';
import StaticMap from '../../map/StaticMap';
import IcoArrow from '../../../assets/ico-arrow.png';

const Row = ({children}) => {
  return <div className='flex flex-row'>{children}</div>;
};

const Campo = ({nombre}) => {
  return <h2 className={'w-[200px] font-semibold my-2 p-2'}>{nombre}:</h2>;
};

const convertToAmPm = time => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours, minutes);

  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const Summary = ({
  informacionPlaza,
  onSubmit,
  onBack,
  mostrarAcciones = true,
}) => {
  const {
    titulo,
    tipoPlaza,
    descripcionCorta,
    descripcion,
    contactoDescripcion,
    perfil,
    cantidadPlazas,
    tipoSalario,
    salario,
    habilidades,
    turnos,
    localizacion,
  } = informacionPlaza;

  return (
    <Layout
      titulo='Resumen de Plaza'
      textoAyuda='Verifica que toda la información sea correcta.'>
      <div className='w-full'>
        <Row>
          <Campo nombre='Titulo de la Plaza' />
          <p className='my-2 p-2'>{titulo}</p>
        </Row>
        <Row>
          <Campo nombre='Tipo de Plaza' />
          <p className='my-2 p-2'>{tipoPlaza}</p>
        </Row>
        <Row>
          <Campo nombre='Descripción Corta' />
          <p className='my-2 p-2'>{descripcionCorta}</p>
        </Row>
        <Row>
          <Campo nombre='Descripción' />
          <p
            className='my-2 p-2'
            dangerouslySetInnerHTML={{__html: descripcion}}></p>
        </Row>
        <Row>
          <Campo nombre='Contacto' />
          <p className='my-2 p-2'>{contactoDescripcion}</p>
        </Row>
        <hr></hr>
        <Row>
          <Campo nombre='Perfil' />
          <p className='my-2 p-2'>{perfil}</p>
        </Row>
        <Row>
          <Campo nombre='Vacantes' />
          <p className='my-2 p-2'>{cantidadPlazas}</p>
        </Row>
        <Row>
          <Campo nombre='Salario' />
          <p className='my-2 p-2'>
            ₡{salario}/{tipoSalario}
          </p>
        </Row>
        <hr></hr>
        <h2 className='font-semibold py-2 text-center text-white rounded-[10px] bg-gray-600'>
          Habilidades
        </h2>
        <hr></hr>
        <br></br>
        {habilidades.map(habilidad => (
          <>
            <Row key={habilidad}>
              <Campo nombre={habilidad.habilidad} />
              <p className='my-2 p-2'>{habilidad.experiencia}</p>
              <p className='my-2 p-2'>
                {habilidad.esRequerida === 'Si' ? '(Requerida)' : '(Opcional)'}
              </p>
            </Row>
          </>
        ))}
        <hr></hr>
        {turnos && turnos.length > 0 && (
          <>
            <h2 className='font-semibold mt-4'>Turnos</h2>
            <br></br>
            {turnos.map(turno => (
              <Row key={turno}>
                <Campo nombre={turno.date} />
                <p className='my-2 p-2'>
                  {convertToAmPm(turno.startTime)} a{' '}
                  {convertToAmPm(turno.endTime)}
                </p>
              </Row>
            ))}
          </>
        )}
        <h2 className='font-semibold py-2 text-center rounded-[10px] bg-gray-600 text-white'>
          Ubicación
        </h2>
        <Row>
          <Campo nombre='Dirección' />
          <p className='my-2 p-2'>
            {localizacion.provinciaNombre}, {localizacion.cantonNombre},{' '}
            {localizacion.distritoNombre}
          </p>
          <p className='my-2 p-2'></p>
          <p className='my-2 p-2'></p>
        </Row>
        <Row>
          <Campo nombre='Señas Adicionales' />
          <p className='my-2 p-2'>{localizacion.direccionExacta}</p>
        </Row>
        <Row>
          <StaticMap coordinates={[localizacion.lng, localizacion.lat]} />
        </Row>
        {mostrarAcciones && (
          <div className='w-full flex justify-end py-6'>
            <button
              onClick={onBack}
              className='flex p-4 font-medium items-center'>
              Atrás
            </button>
            <button
              type='button'
              onClick={onSubmit}
              className='flex text-white font-medium items-center bg-[#FF6652] p-2 px-3 rounded-[60px]'>
              Confirmar y guardar
              <img className='ml-2 w-[55px]' src={IcoArrow} />
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Summary;
