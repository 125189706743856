import React from 'react';

const FormValidacion = ({error}) => {
  return error !== 'valid' && error !== '' ? (
    <div className='p-2 mb-3 bg-orange-200 rounded-[60px]'>
      <span className='text-[14px] ml-2 text-black italic'>* {error}</span>
    </div>
  ) : null;
};

export default FormValidacion;
