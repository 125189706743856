import React, {useEffect, useState} from 'react';
import MainWrapper from '../components/layout/MainWrapper';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import {useParams} from 'react-router-dom';
import {PlazaAPI} from '../services/plaza';
import {toast} from 'react-toastify';
import LoadingIndicator from '../components/global/LoadingIndicator';
import StaticMap from '../components/map/StaticMap';
import {Avatar, Badge, Button} from '@mui/material';
import ConfirmDialog from '../components/global/ConfirmDialog';
import {AplicantesAPI} from '../services/aplicantes';
import {
  BsArrowBarLeft,
  BsBack,
  BsEnvelopeAtFill,
  BsEye,
  BsEyeFill,
  BsPhoneFill,
  BsTelephoneFill,
} from 'react-icons/bs';
import {IoIosDownload, IoIosEye} from 'react-icons/io';
import {AiOutlineUser} from 'react-icons/ai';
import ConfirmModal from '../components/global/ConfirmModal';
import {FaAddressCard, FaArrowLeft} from 'react-icons/fa';

const Detalle = () => {
  let {id} = useParams();
  const [Cargando, setCargando] = useState(true);
  const [cargandoAplicantes, setCargandoAplicantes] = useState(true);
  const [informacionPlaza, setInformacionPlaza] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [aplicantes, setAplicantes] = useState([]);
  const [mostrarDatosCandidato, setMostrarDatosCandidato] = useState(false);
  const [aplicanteDetails, setAplicanteDetails] = useState({});
  const [estaContratando, setEstaContratando] = useState(false);
  const [idUsuarioAContratar, setIdUsuarioAContratar] = useState(null);

  const notifyError = message => toast.error(message);
  const notifyWarning = message => toast.warning(message);
  const notifySuccess = message => toast.success(message);

  const handleOnCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const obtenerAplicantes = () => {
    setCargandoAplicantes(true);
    AplicantesAPI.obtenerResumen(id)
      .then(response => {
        setAplicantes(response);
      })
      .catch(error => {
        notifyError('Ocurrió un error al obtener los aplicantes.');
      })
      .finally(() => {
        setCargandoAplicantes(false);
      });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    PlazaAPI.eliminar(id)
      .then(() => {
        notifySuccess('Plaza eliminada correctamente.');
        setIsDialogOpen(false);
        window.location.href = '/plazas';
      })
      .catch(error => {
        notifyError('Ocurrió un error al eliminar la plaza.');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    const obtenerDetallePlaza = () => {
      PlazaAPI.obtenerDetalle(id)
        .then(response => {
          setInformacionPlaza(response);
        })
        .catch(error => {
          notifyError('Ocurrió un error al obtener el detalle de la plaza.');
        })
        .finally(() => {
          setCargando(false);
        });
    };

    obtenerDetallePlaza();
    obtenerAplicantes();

    return () => {};
  }, []);

  const convertToAmPm = time => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const handleAplicanteDetail = idUsuario => {
    const data = aplicantes.find(x => x.idusuarioColaborador === idUsuario);
    if (data) {
      setAplicanteDetails(data);
      setMostrarDatosCandidato(true);
    } else {
      notifyError('Error al obtener el detalle del aplicante.');
      setAplicanteDetails({});
    }
  };

  const handleBackToDetails = () => {
    setMostrarDatosCandidato(false);
    setAplicanteDetails({});
  };

  const aplicantesNoContratadosComponente = () => {
    const contratados = aplicantes.filter(x => !x.contratado);
    return (
      <div className='space-y-2'>
        {contratados.map(aplicante => (
          <a
            onClick={() =>
              handleAplicanteDetail(aplicante.idusuarioColaborador)
            }
            key={aplicante.idusuarioColaborador}
            className={`flex items-center justify-between px-2 py-2 rounded-md transition-colors cursor-pointer ${
              aplicante.contratado
                ? 'bg-green-100 text-green-900'
                : 'hover:bg-gray-200'
            }`}>
            <div className='flex flex-row place-content-around items-center'>
              <span>
                <AiOutlineUser className='h-5 w-5 mr-3 text-gray-500' />
              </span>
              <span>
                {aplicante.nombre} {aplicante.apellidos}
              </span>
              {aplicante.contratado && (
                <span className='bg-green-500 text-white px-2 py-1 rounded-full text-xs'>
                  Contratado
                </span>
              )}
            </div>
          </a>
        ))}
      </div>
    );
  };

  const aplicantesContratadosComponente = () => {
    const contratados = aplicantes.filter(x => x.contratado);
    return (
      <div className='space-y-2'>
        {contratados.map(aplicante => (
          <a
            onClick={() =>
              handleAplicanteDetail(aplicante.idusuarioColaborador)
            }
            key={aplicante.idusuarioColaborador}
            className={`flex items-center justify-between px-2 py-2 rounded-md transition-colors cursor-pointer ${
              aplicante.contratado
                ? 'bg-green-100 text-green-900'
                : 'hover:bg-gray-200'
            }`}>
            <div className='flex flex-row place-content-around items-center'>
              <span>
                <AiOutlineUser className='h-5 w-5 mr-3 text-gray-500' />
              </span>
              <span>
                {aplicante.nombre} {aplicante.apellidos}
              </span>
              {aplicante.contratado && (
                <span className='bg-green-500 text-white px-2 py-1 rounded-full text-xs'>
                  Contratado
                </span>
              )}
            </div>
          </a>
        ))}
      </div>
    );
  };

  const totalContratados = aplicantes.filter(x => x.contratado).length;
  const totalCandidatos = aplicantes.filter(x => !x.contratado).length;

  /* Confirmacion de Contratacion */
  const [mostrarConfirmacionContratacion, setMostrarConfirmacionContratacion] =
    useState(false);

  const handleContratarCandidato = idUsuario => {
    if (informacionPlaza.cantidadPlazas === totalContratados) {
      notifyWarning('Ya las plazas están completas.');
      setMostrarConfirmacionContratacion(false);
      setIdUsuarioAContratar(null);
      return;
    }

    setIdUsuarioAContratar(idUsuario);
    setMostrarConfirmacionContratacion(true);
  };

  const handleConfirmacionContratacion = () => {
    setEstaContratando(true);
    AplicantesAPI.contratar(id, idUsuarioAContratar)
      .then(() => {
        setEstaContratando(false);
        setMostrarDatosCandidato(false);
        setMostrarConfirmacionContratacion(false);
        setIdUsuarioAContratar(null);
        notifySuccess('Candidato contratado correctamente.');
        obtenerAplicantes();
      })
      .catch(() => {
        setEstaContratando(false);
        notifyError('Error al contratar el candidato.');
      });
  };

  const handleOnCloseConfirmacionContratacion = () => {
    setMostrarConfirmacionContratacion(false);
    setIdUsuarioAContratar(null);
  };

  return (
    <MainWrapper>
      <div className='flex flex-row'>
        <h1 className='text-3xl font-medium mr-14'>Detalle de plaza</h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      {/* Other dashboard content */}
      <div className='w-full mt-10'>
        {Cargando ? (
          <LoadingIndicator />
        ) : (
          <div className='grid grid-cols-[300px_1fr] gap-8 p-8'>
            {/* Aplicantes */}
            <div className='bg-[#dce5e7] rounded-lg p-6'>
              <h2 className='text-lg font-semibold mb-4'>
                Candidatos ({totalCandidatos})
              </h2>
              {cargandoAplicantes ? (
                <LoadingIndicator />
              ) : (
                aplicantesNoContratadosComponente()
              )}
              <h2 className='text-lg font-semibold mb-4'>
                Contratados ({totalContratados}/
                {informacionPlaza.cantidadPlazas})
              </h2>
              {cargandoAplicantes ? (
                <LoadingIndicator />
              ) : (
                aplicantesContratadosComponente()
              )}
            </div>
            {mostrarDatosCandidato && (
              <div className='bg-gray-100 rounded-lg p-6'>
                <p className='mt-4 text-sm mb-2 text-gray-500'>
                  <span className='font-semibold'>Aplicación:</span>{' '}
                  {aplicanteDetails.fechaAplicacion}
                  {aplicanteDetails.contratado && (
                    <>
                      <span className='font-semibold ml-3'>Contratación:</span>{' '}
                      {aplicanteDetails.fechaContratacion}
                    </>
                  )}
                </p>
                <div className='flex items-center justify-between'>
                  <h1 className='text-2xl font-bold mb-4'>
                    {aplicanteDetails.nombre} {aplicanteDetails.apellidos} -{' '}
                    {aplicanteDetails.perfil}
                  </h1>
                  <div className='flex items-center gap-2'>
                    {!aplicanteDetails.contratado && (
                      <Button
                        disabled={estaContratando}
                        color='primary'
                        variant='contained'
                        size='sm'
                        onClick={() =>
                          handleContratarCandidato(
                            aplicanteDetails.idusuarioColaborador,
                          )
                        }>
                        {!aplicanteDetails.contratado &&
                          (estaContratando ? 'Contratando...' : 'Contratar')}
                      </Button>
                    )}
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={handleBackToDetails}
                      startIcon={<FaArrowLeft />}>
                      Volver a la plaza
                    </Button>
                  </div>
                </div>
                <div className='flex items-center gap-4'>
                  <Avatar
                    sx={{width: 75, height: 75}}
                    className='w-24 h-24 border'
                    alt={aplicanteDetails.name}
                    src={`https://lumy-app.s3.amazonaws.com/${aplicanteDetails.urlFoto}`}></Avatar>
                  <div
                    className={`px-2 py-1 rounded-full text-xs ${
                      aplicanteDetails.contratado
                        ? 'bg-green-500  text-white'
                        : 'bg-gray-200 text-gray-900'
                    }`}>
                    {aplicanteDetails.contratado
                      ? 'Contratado'
                      : 'No Contratado'}
                  </div>
                </div>
                <>
                  <h3 className='text-lg font-semibold mt-4'>
                    Información de contacto
                  </h3>
                  <div className='rounded-lg bg-gray-200 mt-2 px-2 py-4'>
                    <p className='flex flex-row'>
                      <BsEnvelopeAtFill className='mr-2 text-gray-500' />
                      {aplicanteDetails.email}
                    </p>
                    <p className='mt-4 flex flex-row'>
                      <BsTelephoneFill className='mr-2 text-gray-500' />
                      {aplicanteDetails.telefono}
                    </p>
                    <p className='mt-4 flex flex-row'>
                      <FaAddressCard className='mr-2 text-gray-500' />
                      {aplicanteDetails.provincia}, {aplicanteDetails.canton},{' '}
                      {aplicanteDetails.distrito}
                    </p>
                  </div>
                </>
                <div className='grid gap-4 py-6'>
                  <div className='grid gap-2'>
                    <h3 className='text-lg font-semibold'>Acerca de</h3>
                    <p className='text-gray-500'>
                      {aplicanteDetails.textoSobreMi !== ''
                        ? aplicanteDetails.textoSobreMi
                        : 'No hay información.'}
                    </p>
                  </div>
                  <div className='grid gap-2'>
                    <h3 className='text-lg font-semibold'>Habilidades</h3>
                    <ul className='flex flex-col px-4 py-1 list-disc'>
                      {aplicanteDetails.habilidades.map((habilidad, index) => {
                        return (
                          <li className='mb-2' key={index}>
                            <span className='font-semibold'>
                              {habilidad.habilidad}
                            </span>{' '}
                            - {habilidad.experiencia}
                          </li>
                        );
                      })}
                      {aplicanteDetails.habilidades.length === 0 && (
                        <p className='text-gray-500'>
                          No hay habilidades registradas.
                        </p>
                      )}
                    </ul>
                  </div>
                  <div className='grid gap-2'>
                    <h3 className='text-lg font-semibold'>Archivos</h3>
                    <p className='text-gray-500'>
                      {aplicanteDetails.hojaDelincuencia !== '' ? (
                        <>
                          <Button
                            disabled={estaContratando}
                            color='info'
                            sx={{
                              backgroundColor: '#f0f0f0',
                              color: '#000',
                              '&:hover': {
                                backgroundColor: '#f0f0f0',
                                color: '#000',
                              },
                            }}
                            variant='contained'
                            size='sm'
                            startIcon={<IoIosDownload size={18} />}
                            onClick={() =>
                              window.open(
                                `https://lumy-app.s3.amazonaws.com/${aplicanteDetails.hojaDelincuencia}`,
                                '_blank',
                              )
                            }>
                            Hoja de vida
                          </Button>
                        </>
                      ) : (
                        'No hay archivos.'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* Detalle de la plaza */}
            {!mostrarDatosCandidato && (
              <div>
                <div className='bg-gray-100 rounded-lg p-6'>
                  <div className='flex items-center justify-between'>
                    <h1 className='text-2xl font-bold mb-4'>
                      {informacionPlaza.titulo}
                    </h1>
                    <Button
                      variant='contained'
                      color='error'
                      size='sm'
                      onClick={() => setIsDialogOpen(true)}>
                      Eliminar Plaza
                    </Button>
                  </div>
                  <p className='text-gray-500 mb-6 font-semibold'>
                    {informacionPlaza.perfil}
                  </p>
                  <p className='text-gray-500 mb-6'>
                    {informacionPlaza.descripcionCorta}
                  </p>
                  <p
                    className='text-gray-500 mb-6'
                    dangerouslySetInnerHTML={{
                      __html: informacionPlaza.descripcion,
                    }}></p>
                  <p className='text-gray-500 mt-6'>
                    <span className='font-semibold'>Tipo:</span>{' '}
                    {informacionPlaza.tipoPlaza}
                  </p>
                  <p className='text-gray-500 mt-6'>
                    <span className='font-semibold'>Plazas:</span>{' '}
                    {informacionPlaza.cantidadPlazas}
                  </p>
                  <p className='text-gray-500 mt-6'>
                    <span className='font-semibold'>Salario:</span> ₡
                    {informacionPlaza.salario} {informacionPlaza.tipoSalario}
                  </p>
                  <p className='text-gray-500 mt-6'>
                    <span className='font-semibold'>Contacto directo:</span>{' '}
                    {informacionPlaza.contactoDescripcion}
                  </p>
                  <p className='text-gray-500 mt-6'>
                    <span className='font-semibold'>Fecha límite:</span>{' '}
                    {informacionPlaza.fechaInicio}
                  </p>
                  <h2 className='text-lg font-semibold mb-4 mt-6'>
                    Habilidades
                  </h2>
                  <ul className='list-disc pl-6 space-y-2'>
                    {informacionPlaza.habilidades.map((habilidad, index) => (
                      <li key={index}>
                        {habilidad.habilidad} - {habilidad.experiencia}{' '}
                        {habilidad.esRequerida === 'Si'
                          ? '(Requerida)'
                          : '(Opcional)'}
                      </li>
                    ))}
                  </ul>
                  {informacionPlaza.turnos &&
                    informacionPlaza.turnos.length > 0 && (
                      <div>
                        <h2 className='text-lg font-semibold mb-4 mt-6'>
                          Turnos
                        </h2>
                        <ul className='list-disc pl-6 space-y-2'>
                          {informacionPlaza.turnos.map((turno, index) => (
                            <li key={index}>
                              {turno.date} - {convertToAmPm(turno.startTime)} a{' '}
                              {convertToAmPm(turno.endTime)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  <h2 className='text-lg font-semibold mb-4 mt-6'>Ubicación</h2>
                  <p className='text-gray-500 mt-6'>
                    {informacionPlaza.localizacion.provinciaNombre},{' '}
                    {informacionPlaza.localizacion.cantonNombre},{' '}
                    {informacionPlaza.localizacion.distritoNombre}
                  </p>
                  <p className='text-gray-500 mt-6'>
                    {informacionPlaza.localizacion.direccionExacta}
                  </p>
                  <div className='mt-6'>
                    <StaticMap
                      coordinates={[
                        informacionPlaza.localizacion.lng,
                        informacionPlaza.localizacion.lat,
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ConfirmDialog
        open={isDialogOpen}
        title='Eliminar Plaza'
        description='¿Está seguro que desea eliminar esta plaza? Esta acción no se puede deshacer.'
        onClose={handleOnCloseDialog}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <ConfirmModal
        open={mostrarConfirmacionContratacion}
        title='Confirmar acción'
        description='¿Está seguro que desea proceder con la contratación?'
        onClose={handleOnCloseConfirmacionContratacion}
        onConfirm={handleConfirmacionContratacion}
        estaProcesando={estaContratando}
        labelBotonLoading='Contratando...'
        labelNo='No, cancelar'
        labelSi='Sí, contratar'
        botonColor='success'
      />
    </MainWrapper>
  );
};

export default Detalle;
