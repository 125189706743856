import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmDialog = ({
  open,
  onClose,
  isDeleting,
  onConfirm,
  title = 'Confirm Delete',
  description = 'Are you sure you want to delete this item?',
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!isDeleting && (
          <Button disabled={isDeleting} onClick={onClose}>
            No, Cancelar
          </Button>
        )}
        <Button
          disabled={isDeleting}
          onClick={onConfirm}
          color='error'
          autoFocus>
          {isDeleting ? 'Eliminado...' : 'Si, Eliminar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
