import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {api} from '../config';

export const AplicantesAPI = {
  obtenerResumen: async function (idPlaza) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const empresa = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);

    const response = await api.request({
      url: `/api/v1/Aplicantes/${empresa}/${idPlaza}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  contratar: async function (idPlaza, idUsuario) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    const response = await api.request({
      url: `/api/v1/Aplicantes/${idUsuario}/plaza/${idPlaza}/contratar`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
};
