import React, {useEffect} from 'react';
import BaseWidget from './BaseWidget';
import {useState} from 'react';
import {PlazaAPI} from '../../services/plaza';

const WidgetVacantes = () => {
  const [contador, setContador] = useState('0');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVacantes = async () => {
      try {
        const response = await PlazaAPI.obtenerVacantes();
        setContador(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchVacantes();
  }, []);

  if (loading) {
    return <BaseWidget titulo='Vacantes' contador='...' />;
  }

  if (error) {
    return <BaseWidget titulo='Vacantes' contador='Error' />;
  }

  return (
    <>
      <BaseWidget titulo='Vacantes' contador={contador} />
    </>
  );
};

export default WidgetVacantes;
