import React, {useState} from 'react';
import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {ContactosAPI} from '../../services/contactos';
import FormValidacion from '../validacion/FormValidacion';
import IcoArrow from '../../assets/ico-arrow.png';
import Input from '../elements/input';
import InputMask from 'react-input-mask';
import * as Sentry from '@sentry/react';

const NuevoContacto = ({closeForm}) => {
  const [nombre, setNombre] = useState('');
  const [nombreError, setNombreError] = useState('');
  const [telefono, setTelefono] = useState('');
  const [telefonoError, setTelefonoError] = useState('');
  const [loading, setLoading] = useState(false);

  const crearContacto = () => {
    const request = {
      idEmpresaContacto: 0,
      idEmpresa: sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID),
      nombre: nombre,
      telefono: telefono,
      activo: 0,
    };

    ContactosAPI.crear(request)
      .then(() => {
        setLoading(false);
        closeForm(false);
        setNombre('');
        setNombreError('');
        setTelefonoError('');
        setTelefono('');
        closeForm();
      })
      .catch(error => {
        Sentry.captureException(error);
        setLoading(false);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    let isFormValid = true;

    if (nombre === '') {
      setNombreError('El nombre de contacto es requerido.');
      isFormValid = false;
    } else {
      setNombreError('valid');
    }

    if (telefono === '') {
      setTelefonoError('El teléfono es requerido.');
      isFormValid = false;
    } else {
      setTelefonoError('valid');
    }

    if (telefono.length < 8) {
      setTelefonoError('Teléfono no válido.');
      isFormValid = false;
    } else {
      setTelefonoError('valid');
    }

    if (isFormValid) {
      setLoading(true);
      crearContacto();
    }
  };
  return (
    <div className='w-[450px] p-5'>
      <form>
        <div className='flex flex-col'>
          <Input
            label='Nombre del Contacto'
            type='text'
            placeholder='e.g. Juan Pérez'
            value={nombre}
            validacion={nombreError}
            onChange={e => {
              if (e.target.value !== '') {
                setNombreError('valid');
              } else {
                setNombreError('El nombre de contacto es requerido.');
              }
              setNombre(e.target.value);
            }}
          />
        </div>
        <div className='flex flex-col'>
          <label htmlFor='telefono'>Teléfono:</label>
          <InputMask
            className={`my-2 rounded-[60px] p-3 w-full ${
              telefonoError !== '' && telefonoError !== 'valid' && 'bg-red-200'
            }`}
            placeholder='(*) Teléfono'
            mask='9999-9999'
            value={telefono.replace('-', '')}
            onChange={e => {
              let value = e.target.value.replace(/-/g, '').replace(/_/g, '');
              if (value !== '') {
                if (value.length < 8) {
                  setTelefonoError('Teléfono no válido.');
                } else {
                  setTelefonoError('valid');
                }
              } else {
                setTelefonoError('El teléfono es requerido.');
              }
              setTelefono(value);
            }}
          />
          <FormValidacion error={telefonoError} />
        </div>

        <div className='w-full flex justify-end py-6'>
          <button
            type='button'
            onClick={handleSubmit}
            disabled={loading}
            className='flex text-white font-medium items-center bg-black p-4 rounded-[60px]'>
            {loading ? (
              <span className='animate-pulse'>Creando...</span>
            ) : (
              <>
                Agregar Contacto
                <img className='ml-2 w-[55px]' src={IcoArrow} />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NuevoContacto;
