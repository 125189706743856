import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import './HabilidadesSuggestion.css';
import {toast} from 'react-toastify';
import * as Sentry from '@sentry/react';
import {PerfilAPI} from '../../../services/perfil';

const PerfilSuggestion = ({perfil, onPerfilChange}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(perfil ?? '');

  const notifyError = message => toast.error(message);

  const onChange = (event, {newValue}) => {
    setValue(capitalizeWords(newValue));
    onPerfilChange(capitalizeWords(newValue));
  };

  const onSuggestionsFetchRequested = ({value}) => {
    if (value.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      PerfilAPI.obtenerSugerencia(value)
        .then(response => {
          setSuggestions(response);
        })
        .catch(error => {
          Sentry.captureException(error);
          notifyError('Error al obtener sugerencias de perfil.');
        });
    } catch (error) {
      Sentry.captureException(error);
      notifyError('Error al obtener sugerencias de perfil.');
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Ej. Bodeguero, Cajero, etc...',
    value: value,
    onChange: onChange,
    onKeyDown: event => {
      if (
        (event.key === 'Tab' || event.key === 'Enter') &&
        suggestions.length > 0
      ) {
        event.preventDefault();
        setValue(suggestions[0].titulo);
      }
    },
  };

  const themeProps = {
    input: 'my-2 rounded-[10px] p-3 w-full',
    suggestionsContainer: 'rounded-[10px] bg-white -mt-1 z-10',
    suggestion: 'suggestion',
    suggestionHighlighted: 'bg-gray-300',
  };

  function capitalizeWords(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach it how to do it.
  const getSuggestionValue = suggestion => suggestion.titulo;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => <div>{suggestion.titulo}</div>;

  return (
    <>
      <Autosuggest
        id='perfil'
        value={value}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={themeProps}
      />
    </>
  );
};

export default PerfilSuggestion;
