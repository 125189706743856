import React, {useState} from 'react';
import {IoMdCopy, IoIosRemoveCircleOutline} from 'react-icons/io';
import {IoCalendarNumberOutline, IoAddOutline} from 'react-icons/io5';
import {AiOutlineEdit} from 'react-icons/ai';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IcoArrow from '../../assets/ico-arrow.png';
import es from 'date-fns/locale/es';
import {DataGrid, esES} from '@mui/x-data-grid';
import {Button} from '@mui/material';

registerLocale('es', es);

const ScheduleSlotComponent = ({
  handleOnSubmit,
  fechaInicio,
  setPasoActual,
}) => {
  const [schedule, setSchedule] = useState([]);
  const [date, setDate] = useState(new Date(fechaInicio));
  const [startTime, setStartTime] = useState('06:00');
  const [endTime, setEndTime] = useState('07:00');
  const [editIndex, setEditIndex] = useState(null);

  const generateUniqueId = () => schedule.length + 1;

  const turnosOrdenados = [...schedule].sort(
    (a, b) => new Date(a.fullDate) - new Date(b.fullDate),
  );

  const copyScheduleSlot = row => {
    try {
      const rowToCopy = turnosOrdenados[turnosOrdenados.length - 1];
      const copiedSlot = {...rowToCopy, id: generateUniqueId()};
      const nextDay = new Date(copiedSlot.fullDate);
      nextDay.setDate(nextDay.getDate() + 1);
      copiedSlot.fullDate = nextDay;
      copiedSlot.date = nextDay.toLocaleDateString('es-ES');

      setSchedule([...schedule, copiedSlot]);
    } catch (error) {
      notifyError('Ocurrió un error al copiar el turno.');
    }
  };

  const editScheduleSlot = row => {
    const index = schedule.findIndex(slot => slot.id === row.id);
    const slotToEdit = schedule[index];
    setEditIndex(index);
    setDate(new Date(slotToEdit.fullDate));

    setStartTime(slotToEdit.startTime);
    setEndTime(slotToEdit.endTime);
  };

  const removeScheduleSlot = row => {
    const updatedSchedule = schedule.filter(slot => slot.id !== row.id);
    setSchedule(updatedSchedule);
    setEditIndex(null);
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Fecha',
      width: 110,
      headerClassName: 'font-bold',
    },
    {field: 'startTime', headerName: 'Hora Inicio', width: 140},
    {field: 'endTime', headerName: 'Hora Fin', width: 140},
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 120,
      headerAlign: 'left',
      renderCell: params => (
        <div className='flex flex-row justify-center'>
          <strong className='flex flex-row items-center'>
            <a
              title='Copiar turno'
              className='cursor-pointer mr-2 text-blue-800'
              onClick={() => copyScheduleSlot(params.row)}>
              <IoMdCopy />
            </a>
            <a
              title='Editar'
              className='cursor-pointer mr-2 text-blue-800'
              onClick={() => editScheduleSlot(params.row)}>
              <AiOutlineEdit />
            </a>
            <a
              title='Eliminar turno'
              className='cursor-pointer text-red-600'
              onClick={() => removeScheduleSlot(params.row)}>
              <IoIosRemoveCircleOutline />
            </a>
          </strong>
        </div>
      ),
    },
  ];

  const formatTimeTo12Hour = time24 => {
    const [hour, minute] = time24.split(':');
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    return `${formattedHour}:${minute} ${
      parseInt(hour, 10) >= 12 ? 'PM' : 'AM'
    }`;
  };

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        if (hour === 23 && minute === 45) break;
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedTime24 = `${formattedHour}:${formattedMinute}`;
        const formattedTime12 = formatTimeTo12Hour(formattedTime24);
        timeSlots.push({time24: formattedTime24, time12: formattedTime12});
      }
    }
    return timeSlots;
  };

  const generateEndTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        if (hour === 0 && minute === 0) continue;
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedTime24 = `${formattedHour}:${formattedMinute}`;
        const formattedTime12 = formatTimeTo12Hour(formattedTime24);
        timeSlots.push({time24: formattedTime24, time12: formattedTime12});
      }
    }
    return timeSlots;
  };

  const notifyError = message => toast.error(message);
  const notifySuccess = message => toast.success(message);

  const validateScheduleSlot = (currentDate, startDateTime, endDateTime) => {
    if (startDateTime < currentDate) {
      notifyError(
        'La fecha seleccionada debe ser igual o posterior a la actual.',
      );
      return false;
    }
    if (endDateTime <= startDateTime) {
      notifyError('La hora de fin debe ser mayor que la hora de inicio.');
      return false;
    }
    const slotDate = date.toLocaleDateString('es-ES');
    const dateAlreadyExists = schedule.some(slot => slot.date === slotDate);
    if (dateAlreadyExists) {
      notifyError('Ya existe un turno para la fecha seleccionada.');
      return false;
    }
    return true;
  };

  const addScheduleSlot = () => {
    if (!date || !startTime || !endTime) {
      notifyError(
        'Por favor, completa todos los campos antes de agregar o actualizar un turno.',
      );
      return;
    }

    const currentDate = new Date(fechaInicio);
    const formattedDate = date.toLocaleDateString('en-US');
    const startDateTime = new Date(`${formattedDate} ${startTime}`);
    const endDateTime = new Date(`${formattedDate} ${endTime}`);

    if (validateScheduleSlot(currentDate, startDateTime, endDateTime)) {
      const newSlot = {
        id: generateUniqueId(),
        date: date.toLocaleDateString('es-ES'),
        fullDate: date,
        startTime,
        endTime,
      };

      if (editIndex !== null) {
        const updatedSchedule = [...schedule];
        updatedSchedule[editIndex] = newSlot;
        setSchedule(updatedSchedule);
        setEditIndex(null);
        notifySuccess('¡Turno actualizado exitosamente!');
      } else {
        setSchedule([...schedule, newSlot]);
        notifySuccess('¡Turno agregado exitosamente!');
      }

      setDate(new Date(fechaInicio));
      setStartTime('06:00');
      setEndTime('07:00');
    }
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setDate(new Date(fechaInicio));
    setStartTime('06:00');
    setEndTime('07:00');
  };

  return (
    <div className='w-full mx-auto p-2 rounded-lg'>
      <div className='inline-block'>
        <label htmlFor='Fecha' className='block mb-2'>
          Fecha:
        </label>
        <DatePicker
          showIcon
          locale='es'
          selected={date}
          onChange={date => setDate(date)}
          dateFormat='dd/MM/yyyy'
          minDate={new Date(fechaInicio)}
          icon={<IoCalendarNumberOutline />}
          className='rounded-full py-2 px-4 border border-gray-300 focus:outline-none focus:border-[#FF6652]'
        />
      </div>
      <div className='inline-block ml-4'>
        <label className='block mb-2'>Hora Inicio:</label>
        <select
          value={startTime}
          onChange={e => setStartTime(e.target.value)}
          className='rounded-full py-2 px-4 border border-gray-300 focus:outline-none focus:border-[#FF6652]'>
          {generateTimeSlots().map(time => (
            <option key={time.time24} value={time.time24}>
              {time.time12}
            </option>
          ))}
        </select>
      </div>

      <div className='inline-block ml-4'>
        <label className='block mb-2'>Hora Fin:</label>
        <select
          value={endTime}
          onChange={e => setEndTime(e.target.value)}
          className='rounded-full py-2 px-4 border border-gray-300 focus:outline-none focus:border-[#FF6652]'>
          {generateEndTimeSlots().map(time => (
            <option key={time.time24} value={time.time24}>
              {time.time12}
            </option>
          ))}
        </select>
      </div>
      <div className='inline-block ml-4'>
        {editIndex !== null ? (
          <>
            <button
              onClick={addScheduleSlot}
              className='bg-black text-sm text-white rounded-[20px] p-2 ml-4'>
              Actualizar Turno
            </button>
            <button
              onClick={cancelEdit}
              className='text-gray-600 text-sm ml-4 underline hover:text-gray-800 focus:outline-none'>
              Cancelar
            </button>
          </>
        ) : (
          <button
            onClick={addScheduleSlot}
            className='bg-black flex items-center text-sm text-white rounded-[20px] p-2 ml-4'>
            <IoAddOutline className='text-white w-5 h-5' />
            Agregar Turno
          </button>
        )}
      </div>
      {schedule.length > 0 && (
        <div className='mt-6 w-full max-h-[400px] overflow-auto'>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={turnosOrdenados}
            columns={columns}
            sx={{
              '& .font-bold': {
                fontWeight: '700',
              },
              borderRadius: '15px',
              boxShadow: 1,
            }}
          />
        </div>
      )}
      <ToastContainer />
      <div className='w-full flex justify-end py-6'>
        <button
          className='flex font-medium items-center p-4'
          onClick={() => {
            setPasoActual('2');
          }}>
          Atrás
        </button>
        <button
          onClick={() => {
            if (turnosOrdenados.length === 0) {
              notifyError(
                'Por favor, agrega al menos un turno antes de continuar.',
              );
              return;
            }
            handleOnSubmit(turnosOrdenados);
          }}
          type='button'
          className={`flex text-white font-medium items-center bg-black p-4 rounded-[20px] ${
            turnosOrdenados.length === 0 ? 'bg-gray-400' : ''
          }`}>
          Agregar Ubicación
          <img className='ml-2 w-[55px]' src={IcoArrow} />
        </button>
      </div>
    </div>
  );
};

export default ScheduleSlotComponent;
