import React from 'react';
import WidgetCantidadPlazas from '../components/widgets/WidgetCantidadPlazas';
import WidgetPlazasActivas from '../components/widgets/WidgetPlazasActivas';
import WidgetVacantes from '../components/widgets/WidgetVacantes';
import FormularioTrabajo from '../components/trabajos/FormularioTrabajo';
import MainWrapper from '../components/layout/MainWrapper';

const Trabajos = () => {
  return (
    <MainWrapper>
      <div className='flex flex-row'>
        <h1 className='text-3xl font-medium mr-14'>
          Creación de <span className='font-semibold'>Plaza</span>
        </h1>
        <WidgetCantidadPlazas />
        <WidgetPlazasActivas />
        <WidgetVacantes />
      </div>
      {/* Other dashboard content */}
      <FormularioTrabajo />
    </MainWrapper>
  );
};

export default Trabajos;
