import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmModal = ({
  open,
  onClose,
  estaProcesando,
  onConfirm,
  title = 'Confirm Delete',
  description = 'Are you sure you want to delete this item?',
  labelNo = 'No',
  labelSi = 'Si',
  labelBotonLoading = 'Procesando...',
  botonColor = 'primary' | 'secondary' | 'error' | 'info' | 'success',
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!estaProcesando && (
          <Button disabled={estaProcesando} onClick={onClose}>
            {labelNo}
          </Button>
        )}
        <Button
          disabled={estaProcesando}
          variant='contained'
          onClick={onConfirm}
          color={botonColor}>
          {estaProcesando ? labelBotonLoading : labelSi}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
