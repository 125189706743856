import LOCAL_STORAGE_KEYS from '../../constants/localStorageKeys';
import {api} from '../config';

export const ContactosAPI = {
  obtener: async function (empresaId) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/EmpresaContacto/${empresaId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  crear: async function (request) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: '/api/v1/EmpresaContacto',
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  editar: async function (request) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: '/api/v1/EmpresaContacto',
      method: 'PUT',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
  inactivar: async function (id) {
    const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const response = await api.request({
      url: `/api/v1/EmpresaContacto/inactivate/${id}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  },
};
