import React from 'react';
import {DataGrid, esES} from '@mui/x-data-grid';
import {Button} from '@mui/material';
import {IoIosRemoveCircleOutline} from 'react-icons/io';

const ListaHabilidades = ({habilidades, onDelete}) => {
  const columns = [
    {
      field: 'habilidad',
      headerName: 'Habilidad',
      width: 150,
      headerClassName: 'font-bold',
      renderCell: params => {
        return (
          <div className='whitespace-normal break-words'>{params.value}</div>
        );
      },
    },
    {field: 'experiencia', headerName: 'Experiencia', width: 150},
    {field: 'esRequerida', headerName: '¿Requerida?', width: 150},
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      headerAlign: 'center',
      renderCell: params => {
        return (
          <strong>
            <Button
              variant='outlined'
              color='error'
              size='small'
              style={{marginLeft: 16}}
              onClick={() => onDelete(params.row)}
              sx={{borderRadius: '10px'}}
              startIcon={<IoIosRemoveCircleOutline />}>
              Eliminar
            </Button>
          </strong>
        );
      },
    },
  ];

  return (
    <>
      {!habilidades || habilidades.length === 0 ? (
        <p className='text-center text-black'>
          No hay ninguna habilidad agregada.
        </p>
      ) : (
        <div>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={habilidades}
            columns={columns}
            sx={{
              '& .font-bold': {
                fontWeight: '700', // Apply bold styling similar to Tailwind's font-bold
              },
              borderRadius: '15px',
              backgroundColor: '#f4f4f4',
              border: 0,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ListaHabilidades;
