import React, {useEffect} from 'react';
import {Routes as Router, Route, Navigate, Outlet} from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Trabajos from './pages/Trabajos';
import Registro from './pages/Registro';
import Contactos from './pages/Contactos';
import LOCAL_STORAGE_KEYS from './constants/localStorageKeys';
import Plazas from './pages/ListaPlazas';
import Detalle from './pages/Detalle';
import DetalleAplicantes from './pages/DetalleAplicantes';
import RequestError from './pages/RequestError';
import Configuracion from './pages/Configuracion';
import RecuperarClave from './pages/RecuperarClave.jsx';
import ConfirmarClave from './pages/ConfirmarClave';

const PrivateRoutes = () => {
  const token = sessionStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  const empresaId = sessionStorage.getItem(LOCAL_STORAGE_KEYS.EMPRESA_ID);
  const usuarioId = sessionStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  if (
    !token ||
    !empresaId ||
    !usuarioId ||
    token === '' ||
    empresaId === '' ||
    usuarioId === ''
  ) {
    return <Navigate to='/login' replace />;
  }

  return <Outlet />;
};

const Routes = () => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      //sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <Route path='/login' element={<Login />} />
      <Route path='/registro' element={<Registro />} />
      <Route path='/confirmar-clave/:token' element={<ConfirmarClave />} />
      <Route path='/recuperar-clave' element={<RecuperarClave />} />
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/nueva-plaza' element={<Trabajos />} />
        <Route path='/contactos' element={<Contactos />} />
        <Route path='/plazas' element={<Plazas />} />
        <Route path='/detalle/:id' element={<Detalle />} />
        <Route path='/aplicantes/:plazaId' element={<DetalleAplicantes />} />
        <Route path='/error' element={<RequestError />} />
        <Route path='/configuracion' element={<Configuracion />} />
      </Route>
    </Router>
  );
};

export default Routes;
