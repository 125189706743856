import React from 'react';

const MensajeValidacion = props => {
  const {error} = props;
  return (
    <div>
      {error !== 'valid' && error !== '' && (
        <span className='text-[16px] ml-4 text-red-800'>* {error}</span>
      )}
    </div>
  );
};

export default MensajeValidacion;
